import React, {useEffect, useState} from 'react';
import {
    InputGroup,
    InputRightElement,
    Flex,
    Box,
    Center,
    Stack,
    Text,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Divider,
    Image,
    useDisclosure,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    FormControl,
    FormLabel,
    Input,
    Radio,
    RadioGroup,
    Select, HStack,
    useBreakpointValue,
    IconButton,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import {
    HamburgerIcon,
    AddIcon,
    ExternalLinkIcon,
    RepeatIcon,
    EditIcon,
} from '@chakra-ui/icons'
import {LoremIpsum} from "react-lorem-ipsum";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {Link} from "react-router-dom";
import { FiMenu } from 'react-icons/fi'
import {BsBook, BsPersonLinesFill} from "react-icons/bs";
import {FaListOl} from "react-icons/fa";
import {GrHistory} from "react-icons/gr";
import {BiTestTube} from "react-icons/bi";
import {signOut} from "firebase/auth";
import UserPanel from "./UserPanel";
import {logout} from "../../../firebase/config";
import {updateUser} from "../../../firestore/collection";
import ModalBegin from "./Modal";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useForm, useFormState} from "react-hook-form";
import {schoolList, yearList, branchList} from '../variables'
import {MdVisibility, MdVisibilityOff} from 'react-icons/md'
import {validatePassword} from '../../../firebase/register'
import {changePassword, changePassword2} from '../../../firebase/config'

const Placeholder = (props: any) => {
    return <Box role="presentation" py="5" px="4" color="on-accent" {...props} />;
};

const menuListItem = [
    {
        title: "ทดสอบก่อนเรียน",
        icon: <BiTestTube/>,
        link: '',
        page: 'external'
    },
    {
        title: "คอร์ส",
        icon: <FaListOl/>,
        link: "course",
        page: 'internal'
    },
    {
        title: "คอร์สของฉัน",
        icon: <BsPersonLinesFill/>,
        link: "my-course",
        page: 'internal'
    },
    {
        title: "คอร์สย้อนหลัง",
        icon: <GrHistory/>,
        link: "course-hist",
        page: 'internal'
    },
];
type FormValues = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    school: string;
    branch: string;
    year: string;
    role: boolean;
    offerPromotion: boolean;
    type: string;
    credit: number;
}

type DDLCenterModel = {
    text: string,
    value: string,
};

type changePasswordType = {
    passwordOld: string,
    passwordNew: string,
    passwordNewConfirm: string,
}

const ChangePassword = (props: any) => {
    // console.log(props.user);
    const [showPasswordOld, setShowPasswordOld] = useState<boolean>(false);
    const [showPasswordNew, setShowPasswordNew] = useState<boolean>(false);
    const [showPasswordNewConfirm, setShowPasswordNewConfirm] = useState<boolean>(false);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const MySwal = withReactContent(Swal);
    const {register, handleSubmit, formState: {errors}} = useForm<changePasswordType>();
    const onSubmit = handleSubmit(async (data: changePasswordType) => {
        if (data.passwordOld === data.passwordNew) {
            MySwal.fire({
                icon: 'error',
                title: 'รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม',
                timer: 2000
            })
        } else {
            const validate = validatePassword(data.passwordNew, data.passwordNewConfirm);
            if (!validate.status) {
                MySwal.fire({
                    icon: 'error',
                    title: validate.message,
                    timer: 3000
                })
            } else {
                console.log(props.userPassword);
                if (props.userPassword === data.passwordOld) {
                    setBtnLoading(true);
                    const change = await changePassword2(props.user, data.passwordOld, data.passwordNew);
                    if (change.status) {
                        MySwal.fire({
                            title: change.message,
                            icon: 'success',
                            timer: 3000,
                        })
                        props.callOnClose();
                        props.callMutate();
                    } else {
                        MySwal.fire({
                            title: change.message,
                            icon: 'error',
                            timer: 3000,
                        })
                    }
                    setBtnLoading(false);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'รหัสผ่านเดิมไม่ถูกต้อง',
                        timer: 3000
                    })
                }
            }

        }
    })

    // console.log(props.user.uid)
    return (
        <form name='form2' id='form2' onSubmit={onSubmit}>
            <Stack spacing='3'>
                <FormControl>
                    <FormLabel htmlFor='passwordOld' flexDirection={"row"}>รหัสผ่านเดิม <span
                        style={{color: 'red'}}>*</span></FormLabel>
                    <InputGroup>
                        <Input
                            id='passwordOld'
                            placeholder="รหัสผ่านเดิม"
                            type={showPasswordOld ? 'text' : 'password'}
                            {...register("passwordOld", {required: "กรุณากรอกรหัสผ่านเดิม"})}
                        />
                        <InputRightElement width='auto' onClick={() => setShowPasswordOld(!showPasswordOld)}>
                            <Flex align='center' justify='center' w='50px' bgColor='gray.100' h='100%' borderRadius='5'>
                                {showPasswordOld ?
                                    <MdVisibility/> :
                                    <MdVisibilityOff/>
                                }
                            </Flex>
                        </InputRightElement>
                    </InputGroup>
                    <Text color='red'>{errors.passwordOld?.message && errors.passwordOld?.message}</Text>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor='passwordNew'>รหัสผ่านใหม่ <span style={{color: 'red'}}>*</span></FormLabel>
                    <InputGroup>
                        <Input
                            id='passwordNew'
                            placeholder="รหัสผ่านใหม่"
                            type={showPasswordNew ? 'text' : 'password'}
                            {...register("passwordNew", {
                                required: "กรุณากรอกรหัสผ่านใหม่", minLength: {
                                    value: 8,
                                    message: 'กรุณากรอกรหัสผ่านไม่น้อยกว่า 8 ตัวอักษร'
                                }
                            })}
                        />
                        <InputRightElement width='auto' onClick={() => setShowPasswordNew(!showPasswordNew)}>
                            <Flex align='center' justify='center' w='50px' bgColor='gray.100' h='100%' borderRadius='5'>
                                {showPasswordNew ?
                                    <MdVisibility/> :
                                    <MdVisibilityOff/>
                                }
                            </Flex>
                        </InputRightElement>
                    </InputGroup>
                    <Text color='red'>{errors.passwordNew?.message && errors.passwordNew?.message}</Text>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor='passwordNewConfirm'>ยืนยันรหัสผ่านใหม่ <span
                        style={{color: 'red'}}>*</span></FormLabel>
                    <InputGroup>
                        <Input
                            id='passwordNewConfirm'
                            placeholder="ยืนยันรหัสผ่านใหม่"
                            type='password'
                            {...register("passwordNewConfirm", {
                                required: "กรุณากรอกยืนยันรหัสผ่านใหม่", minLength: {
                                    value: 8,
                                    message: 'กรุณากรอกรหัสผ่านไม่น้อยกว่า 8 ตัวอักษร'
                                }
                            })}
                        />
                        <InputRightElement width='auto'
                                           onClick={() => setShowPasswordNewConfirm(!showPasswordNewConfirm)}>
                            <Flex align='center' justify='center' w='50px' bgColor='gray.100' h='100%' borderRadius='5'>
                                {showPasswordNewConfirm ?
                                    <MdVisibility/> :
                                    <MdVisibilityOff/>
                                }
                            </Flex>
                        </InputRightElement>
                    </InputGroup>
                    <Text color='red'>{errors.passwordNewConfirm?.message && errors.passwordNewConfirm?.message}</Text>
                </FormControl>
                <Button variant="primary" isLoading={btnLoading} fontSize='xl' type='submit'>เปลี่ยนรหัสผ่าน</Button>
            </Stack>
        </form>
    )
}

const ContentBody = (props: any) => {
    const {register, handleSubmit, formState: {errors, isDirty}, watch, setValue, control} = useForm<FormValues>({
        defaultValues: {
            year: "1"
        }
    });
    const [user, setUser] = useState<FormValues>();
    const [uid, setUid] = useState<string>();
    const MySwal = withReactContent(Swal);

    const onSubmit = handleSubmit(async (data: FormValues) => {
        updateUser(data, uid as string).then(data => {
            if (data.status) {
                MySwal.fire({
                    title: "แก้ไขข้อมูลส่วนตัวเรียบร้อยแล้ว",
                    icon: 'success',
                    timer: 3000,
                    didClose: () => {
                        props.callOnClose();
                        props.callMutate();
                    }
                })
            }
        });
    })

    useEffect(() => {
        // console.log(props, 'on contentBody');
        const {userData, uid} = props;

        if (userData) {
            setUser(userData);
            setUid(uid);
            if (userData) {
                // console.log(user);
                setValue('firstName', userData.firstName);
                setValue('lastName', userData.lastName);
                setValue('phone', userData.phone);
                setValue('school', userData.school);
                setValue('branch', userData.branch);
                setValue('year', userData.year);
            }

        }

    }, [props.userData])

    const changeYear = (val: any) => {
        setUser({year: val} as FormValues);
    }

    const inputNumber = (value: any) => {
        // console.log(value);
    }
    return (
        <Tabs>
            <TabList>
                <Tab>แก้ไขข้อมูลส่วนตัว</Tab>
                {/* <Tab>แก้ไขรหัสผ่าน</Tab> */}
            </TabList>

            <TabPanels>
                <TabPanel>
                    <Box>
                        <form name='form1' id='form1' onSubmit={onSubmit}>
                            <Stack spacing="3">
                                <FormControl>
                                    <FormLabel htmlFor="name" fontSize='md'
                                               fontWeight='semibold'>ชื่อ-นามสกุล</FormLabel>
                                    <Stack direction={{base: 'column', sm: 'column', md: 'row'}}>
                                        <Box w='100%'>
                                            <Input
                                                id="firstName"
                                                type="text"
                                                placeholder="ชื่อ"
                                                {...register("firstName", {required: "กรุณากรอกชื่อ"})}

                                            />
                                            <p style={{color: "red"}}>{errors.firstName?.message}</p>
                                        </Box>
                                        <Box w='100%'>
                                            <Input
                                                id="lastName"
                                                type="text"
                                                placeholder="นามสกุล"
                                                {...register("lastName", {required: "กรุณากรอกนามสกุล"})}
                                            />
                                            <p style={{color: "red"}}>{errors.lastName?.message}</p>
                                        </Box>

                                    </Stack>
                                </FormControl>

                                <FormControl>
                                    <FormLabel htmlFor="phone" fontSize='md'
                                               fontWeight='semibold'>เบอร์ติดต่อ</FormLabel>
                                    <Input id="phone"
                                           type="phone"
                                           placeholder="011-111-1111"
                                           {...register("phone", {required: "กรุณากรอกเบอร์ติดต่อ"})}
                                           onClick={(e) => inputNumber(e.currentTarget.value)}
                                    />
                                    <p style={{color: "red"}}>{errors.phone?.message}</p>
                                </FormControl>

                                <FormControl>
                                    <Stack>
                                        <FormLabel htmlFor="school" fontSize='md'
                                                   fontWeight='semibold'>มหาวิทยาลัย</FormLabel>
                                        <Select
                                            placeholder='กรุณาเลือกมหาวิทยาลัย'
                                            style={{color: "black"}}
                                            {...register("school", {required: "กรุณาเลือกมหาวิทยาลัย"})}
                                            isDisabled={true}
                                        >
                                            {
                                                schoolList &&
                                                schoolList.map((val: DDLCenterModel, idx: number) => {
                                                    return <option key={idx} value={val.value}>{val.text}</option>
                                                })
                                            }
                                        </Select>
                                        <p style={{color: "red"}}>{errors.school?.message}</p>
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="branch" fontSize='md' fontWeight='semibold'>สาขาวิชา</FormLabel>
                                    <Select
                                        placeholder='กรุณาเลือกสาขาวิชา'
                                        style={{color: "black"}}
                                        {...register("branch", {required: "กรุณาเลือกสาขาวิชา"})}
                                        isDisabled={true}
                                    >
                                        {
                                            branchList &&
                                            branchList.map((val: DDLCenterModel, idx: number) => {
                                                return <option key={idx} value={val.value}>{val.text}</option>
                                            })
                                        }
                                    </Select>
                                    <p style={{color: "red"}}>{errors.branch?.message}</p>
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="year" fontSize='md' fontWeight='semibold'>ชั้นปี</FormLabel>
                                    <RadioGroup value={user?.year} onChange={(e) => changeYear(e)} isDisabled={true}>
                                        <Stack spacing='2' direction='row'>
                                            {
                                                yearList &&
                                                yearList.map((val: DDLCenterModel, idx: number) => {
                                                    return <Radio key={idx}
                                                                  value={val.value} {...register('year')}>
                                                        {val.text}
                                                    </Radio>
                                                })

                                            }
                                        </Stack>
                                    </RadioGroup>
                                    <Text color="red">{errors.year?.message}</Text>
                                </FormControl>


                                <Button variant="primary" fontSize='xl' type='submit'>แก้ไข</Button>
                            </Stack>
                        </form>
                    </Box>
                </TabPanel>
                {/* <TabPanel>
                    <ChangePassword
                        uid={uid}
                        user={props.user && props.user}
                        userPassword={props.userData && props.userData.password}
                        callMutate={props.callMutate && props.callMutate}
                        callOnClose={props.callOnClose && props.callOnClose}
                    />
                </TabPanel> */}
            </TabPanels>
        </Tabs>
    );
};

const Header = (props: any) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const mdProfire = useDisclosure();
    const mdTest = useDisclosure();
    const drawerMenu = useDisclosure();
    const [user, setUser] = useState<FormValues>();
    const btnRef = React.useRef(null);
    useEffect(() => {
        if (props.userData) {
            setUser(props.userData);
        }
    }, [props])

    const openModalProfile = () => {
        drawerMenu.onClose();
        mdProfire.onOpen();
    }
    return (
        <Box as="nav" role="navigation" bg="whiteAlpha.700" boxShadow={"md"}>
            {/* <Container> */}
            <Placeholder minH="15">
                <Flex align="center" justify="space-between">
                    <Box>
                        <Link to="/app">
                            <Image src="./images/uniclass_logo_crop.png" w="32"/>
                        </Link>
                    </Box>
                    {isDesktop ?
                       ( <>
                            <Center>
                                <Stack spacing="5" direction={{base: "row"}}>
                                    {/* menu */}
                                    {menuListItem.map((val, idx) => {
                                        return (
                                            val.page === 'internal' ?
                                                <Link key={idx} to={val.link}>
                                                    <Flex flexDirection="column">
                                                        <Center>
                                                            <Text>{val.icon}</Text>
                                                        </Center>
                                                        <Text className={'hover:underline'}>{val.title}</Text>
                                                    </Flex>
                                                </Link> :
                                                <ChakraLink key={idx} href='https://forms.office.com/r/D6uCALRyVq' target='_blank'>
                                                    <Flex flexDirection="column" className={'transition relative'}>
                                                        <Center>
                                                            <Text pl={4}>{val.icon}</Text>
                                                        </Center>
                                                        <HStack>
                                                            <Box className={'relative'}>
                                                                <span className="flex h-3 w-3">
                                                                    <span
                                                                        className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                                                    <span
                                                                        className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                                                                </span>
                                                            </Box>
                                                            <Text className={'hover:underline'}>{val.title}</Text>
                                                        </HStack>


                                                        {/*<span className="absolute flex h-3 w-3 top-0 right-0">*/}
                                                        {/*    <span*/}
                                                        {/*        className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>*/}
                                                        {/*    <span*/}
                                                        {/*        className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>*/}
                                                        {/*</span>*/}
                                                    </Flex>
                                                </ChakraLink>
                                        );
                                    })}
                                </Stack>
                            </Center>
                            <Box>
                                {/* <UserPanel userData={props.userData} /> */}
                                <Flex direction={"column"}>
                                    {/* <Button colorScheme={'blue'} onClick={logout}>Sign Out</Button> */}
                                    {/* <Divider /> */}
                                    <Menu>
                                        <MenuButton
                                            as={Button}
                                            rightIcon={<ChevronDownIcon/>}
                                            color="black"
                                        >
                                            {user &&
                                                user.firstName + " " + user.lastName}
                                        </MenuButton>
                                        <MenuList color="black">
                                            <MenuItem onClick={mdProfire.onOpen}>ประวัติส่วนตัว</MenuItem>
                                            <MenuDivider/>
                                            <MenuItem onClick={logout} >ออกจากระบบ</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Flex>
                            </Box>
                        </> ):
                        (
                            <>
                                <IconButton
                                    variant="ghost"
                                    icon={<FiMenu fontSize="1.25rem" />}
                                    aria-label="Open Menu"
                                    onClick={drawerMenu.onOpen}
                                />
                                      <Drawer
                                        isOpen={drawerMenu.isOpen}
                                        placement='right'
                                        onClose={drawerMenu.onClose}
                                        finalFocusRef={btnRef}
                                    >
                                        <DrawerOverlay />
                                        <DrawerContent>
                                        <DrawerCloseButton />
                                        <DrawerHeader></DrawerHeader>

                                        <DrawerBody>
                                            <Stack>
                                                {menuListItem.map((val, idx) => {
                                                    return ( val.page === "internal" &&
                                                        <Link to={val.link} key={idx} onClick={() => drawerMenu.onClose()}>
                                                            <Center>
                                                                <Text>{val.title}</Text>
                                                            </Center>
                                                        </Link>
                                                    )
                                                })}
                                            </Stack>
                                        </DrawerBody>

                                        <DrawerFooter>
                                            <Stack direction="row" w='100%'>
                                                <Button w='100%' colorScheme='blue' onClick={() => openModalProfile()}>โปรไฟล์</Button>
                                                <Button w='100%' colorScheme='red' onClick={logout}>ออกจากระบบ</Button>
                                            </Stack>
                                        </DrawerFooter>
                                        </DrawerContent>
                                    </Drawer>
                            </>
                        )

                    }
                         
                </Flex>

                
                <ModalBegin
                    isOpen={mdProfire.isOpen}
                    onOpen={mdProfire.onOpen}
                    onClose={mdProfire.onClose}
                    size={"6xl"}
                    Title={"ประวัติส่วนตัว"}
                    contentBody={<ContentBody 
                    user={props.user} 
                    callOnClose={mdProfire.onClose}
                    callMutate={props.callMutate} userData={user}
                    uid={props.uid && props.uid}/>}
                />

                {/* <HStack>
                    <Text fontSize='xl'>Logo</Text>
                    <Spacer/>
                    <ButtonGroup variant="link" spacing="8" px={10}>
                        <Link to='/'>
                            <Button>Home</Button>
                        </Link>                        
                    </ButtonGroup>

                </HStack> */}
            </Placeholder>
            {/* </Container> */}
        </Box>
    );
};

export default Header;
