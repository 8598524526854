import {
    Grid,
} from '@chakra-ui/react';
import CourseItem from './CourseItem';

const CourseList = (props: any) => {
    // console.log(props.courseList);
    return (
        <Grid templateColumns={{base:'repeat(1, 1fr)', md:'repeat(2, 1fr)', xl:'repeat(4, 1fr)'}} gap={2}>
            {
                props.courseList &&
                props.courseList.map((val:any, index:number) => 
                    <CourseItem 
                        key={index} 
                        course={val} 
                        userId={props.userId && props.userId} 
                        userCourse={props.userCourse && props.userCourse} 
                        course_list_attd={props.course_list_attd && props.course_list_attd}
                        userCredit={props.userCredit}
                        callMutateAll={props.callMutateAll}
                    />
                ) 
            }
        </Grid>
    )
}


export default CourseList;