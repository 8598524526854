import React, { useState, useEffect } from 'react';
import {
    useClipboard,
    Button,
    Input,
    Center,
    Text,   
    Flex,
    Box,
    Heading,
    Stack,
    Grid,
    GridItem,
    Divider,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    HStack,
    Icon,
    Link as ChakraLink,
    Badge
} from '@chakra-ui/react';
import useSWR from 'swr';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/th';
import DateTimeShow from '../components/DateTimeShow';
import { getAllCourse, getUserCourse } from '../../../firestore/collection';
import { courseType,courseAirtimeType } from '../../../firestore';
import { ZoomIcon, GoogleIcon, SkypeIcon, GoogleMeetIcon } from '../../../components/icons';
import Monitor from '../components/monitor/Monitor';
import {RiGroupFill} from 'react-icons/ri'
import {FiClock} from 'react-icons/fi'

type outletContext =  {
    userData: object;
    uid:string;
    user:object;
}

const trainIcon: {title:string, icon:any}[] = [
    {
        title:'ZOOM',
        icon: <ZoomIcon key={'zoom'} />
    }, 
    {
        title:'SKYPE',
        icon: <SkypeIcon key={'skype'}/>
    }, 
    {
        title:'GOOGLEMEET',
        icon: <GoogleMeetIcon key={'googlemeet'}/>
    }
]

const MyCourseOld = (props: any) => {
    const { userData, uid, user } = useOutletContext<outletContext>();
    const [timer, setTimer] = useState<string>();
    // const tickInterval = setInterval(tick, 1000);
    const [date, setDate] = useState<string>();
    const [userCourse, setUserCourse] = useState();
    // const [allCourse, setAllCourse] = useState<courseType>();
    const courseSkillType = [
        { courseType: "L", courseTypeText: "ฟัง", active:true },
        { courseType: "S", courseTypeText: "พูด", active:true  },
        { courseType: "R", courseTypeText: "อ่าน", active:true  },
        { courseType: "W", courseTypeText: "เขียน", active:true  },
    ]
    const {
        data: dataAllCourse,
        error: errorSWRAll,
        isLoading: lodingAll,
        mutate: mutateAllCourse,
      } = useSWR("/courses/all", getAllCourse);
    const {
        data: dataUserCourse,
        error: errorSWRUserCourse,
        isLoading: loadingUserCourse,
        mutate: mutateUserCourse,
      } = useSWR(
        user ? { url: "/courses/my-course", user: user } : null,
        getUserCourse
      );

    useEffect(() => {
        let year = parseInt(moment().format('YYYY')) + 543;
        setDate(moment().format(`[วัน]dddd [ที่] D [เดือน]MMMM [ปี] ${year}`).toString())
        // console.log(moment().format(`[วัน]dddd [ที่] D [เดือน]MMMM [ปี] ${year}`).toString())
        // setAllCourse(dataAllCourse as courseType);
        // setUserCourse(dataUserCourse as any);

        
    }, []); // component Did Mount

 
    

    const CourseItemA = (course_code_list:[] | undefined) => {
        const course_list = dataAllCourse && dataAllCourse.course_list;
        
        return course_code_list && course_code_list.map((val:string, idx:number) => {
            let course:courseType = course_list.find((course:courseType) => course.course_code === val);

            if(course) {

                let courseSkill = courseSkillType.find((courseSkill:any) => courseSkill.courseType === course.course_skill_type);
                let airtimeOrder = course.course_airtime_list.sort((a, b) => a.airtime_order - b.airtime_order);

                return (
                    <GridItem 
                        key={idx}
                        w='100%'
                        h='auto'
                        bg='white'
                        boxShadow={'xl'}
                        borderRadius={'5'}
                        // _hover={{backgroundColor:'red'}}
                        transition=".2s ease-in-out"
                        >
                            <Box p='3'>
                                <Stack spacing='2'>
                                    <Flex align='center' justify='space-between'>
                                        <Text>{course.course_name} ({courseSkill?.courseTypeText})</Text>
                                        <Text>Time</Text>
                                    </Flex>
                                    <Divider />
                                    <Box>
                                        <Text>รายละเอียด</Text>
                                        <Box pl={{base:'0', md:'5'}}>
                                            <Text>{course.course_desc}</Text>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Text>ตารางเรียน</Text>
                                        <Box pl={{base:0, md:'5'}}>

                                            <TableContainer>
                                                    <Table variant={'simple'}>
                                                        <TableCaption>ตารางเรียน</TableCaption>
                                                        <Thead>
                                                            <Tr>
                                                                <Th align='center'>ครั้งที่</Th>
                                                                <Th>หัวข้อ</Th>
                                                                <Th>วัน/เดือน/ปี</Th>
                                                                <Th>เริ่ม</Th>
                                                                <Th>สิ้นสุด</Th>
                                                                <Th>สถานะ</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                        {
                                                            airtimeOrder && 
                                                            airtimeOrder.map((airtime:courseAirtimeType, idx:number) => {
                                                                const timeStampStart = airtime.datetime_start * 1000;
                                                                const timeStampEnd = airtime.datetime_end * 1000;
                                                                const momentToDay = moment(new Date());
                                                                const momentDateTime = moment(new Date(timeStampStart));
                                                                const year = parseInt(moment().format('YYYY')) + 543;
                                                                const studyDate = momentDateTime.format(`[วัน]dddd [ที่] D [เดือน]MMMM [ปี] ${year}`);
                                                                const momentDateStart = moment(new Date(timeStampStart))
                                                                const momentDateEnd = moment(new Date(timeStampEnd));
                                                                let count = idx + 1;
                                                                const date = momentDateStart.format('ll')
                                                                const dayDiff = momentDateTime.diff(momentToDay, 'days');
                                                                // console.log(momentToDay.add(1, 'months').calendar());

                                                                return (
                                                                    <Tr key={idx}>
                                                                        <Td>{count}</Td>
                                                                        <Td>{airtime.airtime_header}</Td>
                                                                        <Td>{studyDate}</Td>
                                                                        <Td>{momentDateStart.format("LT")}</Td>
                                                                        <Td>{momentDateEnd.format("LT")}</Td>
                                                                        <Td>
                                                                        <span className="relative inline-flex">
                                                                            <button type="button" className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-sky-500 bg-white dark:bg-slate-800 transition ease-in-out duration-150 cursor-not-allowed ring-1 ring-slate-900/10 dark:ring-slate-200/20">
                                                                                Active
                                                                            </button>
                                                                            <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                                                                                <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                                                                        </span>
                                                                        </span>
                                                                        </Td>
                                                                        
                                                                    </Tr>
                                                                    
                                                                )
                                                            })
                                                        }
                                                        </Tbody>
                                                    </Table>
                                            </TableContainer>
                                            
                                        </Box>
                                                                          
                                    </Box>
                                    <Box>
                                        <Text>อาจารย์ผู้สอน</Text>
                                        <Box pl={{base:0, md:5}}>
                                            {
                                                course.course_teacher_list && 
                                                course.course_teacher_list.map((teacher:any, idx:number) => {
                                                    return <Text key={idx}>{teacher.teacher_name}</Text>
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Text>ช่องทางการสอน</Text>
                                        <Box pl={{base:0, md:5}}>
                                            {/* <Text>{course.course_attend_url_type}</Text> */}
                                            {
                                                trainIcon &&
                                                trainIcon.map((val:{title:string, icon:any}, idx:number) => {
                                                    var icon;
                                                    if(val.title === course.course_attend_url_type) {
                                                        icon = val.icon;
                                                    }
                                                    return icon;
                                                } )
                                            }
                                            <Text>Url - <ChakraLink target={'_blank'} href={course.course_attend_url}>Open Link</ChakraLink></Text>
                                        </Box>
                                    </Box>
                                </Stack>
                            </Box>
                    </GridItem>
                )                      
            } else {
                return <Text>Contact to admin</Text>
            }

        })
    }

    const TestTable = (props:any) => {
        return (
        <TableContainer>
            <Table variant='striped' colorScheme='teal'>
            <TableCaption>Imperial to metric conversion factors</TableCaption>
            <Thead>
                <Tr>
                <Th>To convert</Th>
                <Th>into</Th>
                <Th isNumeric>multiply by</Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr>
                <Td>inches</Td>
                <Td>millimetres (mm)</Td>
                <Td isNumeric>25.4</Td>
                </Tr>
                <Tr>
                <Td>feet</Td>
                <Td>centimetres (cm)</Td>
                <Td isNumeric>30.48</Td>
                </Tr>
                <Tr>
                <Td>yards</Td>
                <Td>metres (m)</Td>
                <Td isNumeric>0.91444</Td>
                </Tr>
            </Tbody>
            <Tfoot>
                <Tr>
                <Th>To convert</Th>
                <Th>into</Th>
                <Th isNumeric>multiply by</Th>
                </Tr>
            </Tfoot>
            </Table>
        </TableContainer>
      )
    }

    return <>
        <Box p={{base:'3', md:'7'}}>
            <Stack spacing='3'>
                <Flex align='center' justify='flex-end'>
                    <DateTimeShow />
                </Flex>
                {
                    dataUserCourse && 
                    dataUserCourse.course_code_list.length ? 
                        dataUserCourse.course_code_list.map((val:string, idx:number) => {
                            const course_list = dataAllCourse && dataAllCourse.course_list;
                            let course:courseType = course_list.find((course:courseType) => course.course_code === val);
                            let courseSkill = courseSkillType.find((courseSkill:any) => courseSkill.courseType === course.course_skill_type);
                            let airtimeOrder = course.course_airtime_list.sort((a, b) => a.airtime_order - b.airtime_order);
                            return course ? <Box key={idx} p='3' bgColor='white' boxShadow={'md'} borderRadius={5}>
                                <Stack spacing='3'>
                                    <Flex align={'center'} justify={'space-between'}>
                                        <Text>{course.course_name}</Text>
                                        <Text>Time</Text>
                                    </Flex>
                                    <Divider />
                                    <Box>
                                        <Text>รายละเอียด</Text>
                                        <Box pl={{base:'0', md:'5'}}>
                                            <Text>{course.course_desc}</Text>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Text>ตารางเรียน</Text>
                                        <Box pl={{base:0, md:'5'}}>

                                            <TableContainer>
                                                    <Table variant={'simple'}>
                                                        <TableCaption>ตารางเรียน</TableCaption>
                                                        <Thead>
                                                            <Tr>
                                                                <Th align='center'>ครั้งที่</Th>
                                                                <Th>หัวข้อ</Th>
                                                                <Th>วัน/เดือน/ปี</Th>
                                                                <Th>เริ่ม</Th>
                                                                <Th>สิ้นสุด</Th>
                                                                <Th>สถานะ</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                        {
                                                            airtimeOrder && 
                                                            airtimeOrder.map((airtime:courseAirtimeType, idx:number) => {
                                                                const timeStampStart = airtime.datetime_start * 1000;
                                                                const timeStampEnd = airtime.datetime_end * 1000;
                                                                const momentToDay = moment(new Date());
                                                                const momentDateTime = moment(new Date(timeStampStart));
                                                                const year = parseInt(moment().format('YYYY')) + 543;
                                                                const studyDate = momentDateTime.format(`[วัน]dddd [ที่] D [เดือน]MMMM [ปี] ${year}`);
                                                                const momentDateStart = moment(new Date(timeStampStart))
                                                                const momentDateEnd = moment(new Date(timeStampEnd));
                                                                let count = idx + 1;
                                                                const date = momentDateStart.format('ll')
                                                                const dayDiff = momentDateTime.diff(momentToDay, 'days');
                                                                // console.log(momentToDay.add(1, 'months').calendar());

                                                                return (
                                                                    <Tr key={idx}>
                                                                        <Td>{count}</Td>
                                                                        <Td>{airtime.airtime_header}</Td>
                                                                        <Td>{studyDate}</Td>
                                                                        <Td>{momentDateStart.format("LT")}</Td>
                                                                        <Td>{momentDateEnd.format("LT")}</Td>
                                                                        <Td>
                                                                        <span className="relative inline-flex">
                                                                            <button type="button" className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-sky-500 bg-white dark:bg-slate-800 transition ease-in-out duration-150 cursor-not-allowed ring-1 ring-slate-900/10 dark:ring-slate-200/20">
                                                                                Active
                                                                            </button>
                                                                            <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                                                                                <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                                                                        </span>
                                                                        </span>
                                                                        </Td>
                                                                        
                                                                    </Tr>
                                                                    
                                                                )
                                                            })
                                                        }
                                                        </Tbody>
                                                    </Table>
                                            </TableContainer>
                                            
                                        </Box>
                                        <Box>
                                        <Text>อาจารย์ผู้สอน</Text>
                                        <Box pl={{base:0, md:5}}>
                                            {
                                                course.course_teacher_list && 
                                                course.course_teacher_list.map((teacher:any, idx:number) => {
                                                    return <Text key={idx}>{teacher.teacher_name}</Text>
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Text>ช่องทางการสอน</Text>
                                        <Box pl={{base:0, md:5}}>
                                            {/* <Text>{course.course_attend_url_type}</Text> */}
                                            {
                                                trainIcon &&
                                                trainIcon.map((val:{title:string, icon:any}, idx:number) => {
                                                    var icon;
                                                    if(val.title === course.course_attend_url_type) {
                                                        icon = val.icon;
                                                    }
                                                    return icon;
                                                } )
                                            }
                                            <Text>Url - {course.course_attend_url}</Text>
                                        </Box>
                                    </Box>                          
                                    </Box>
                                </Stack>
                            </Box> :
                            <Box key={idx}>
                                <Text>{val}</Text>
                            </Box>

                        })
                    :
                    <Box p='3'>
                        <Center>
                            <Text>ไม่มีคอร์สเรียน</Text>
                        </Center>
                    </Box>
                }

            </Stack>
        </Box>
    </>
}


const CourseItem = (props:any) => {
    // console.log(props);
    const { onCopy, value, setValue, hasCopied } = useClipboard("");
    const shortMonth = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];

    function showFormateTimeRange(val: any) {
        const dateStart = new Date(val.datetime_start*1000);
        const dateEnd = new Date(val.datetime_end*1000);
        // console.log(val.datetime_start, 'start',  val.datetime_end, 'end')
        const month = shortMonth[dateStart.getMonth()];
        const hStart = ('0' + dateStart.getHours()).slice(-2);
        const mStart = ('0' + dateStart.getMinutes()).slice(-2);
        const hEnd = ('0' + dateEnd.getHours()).slice(-2);
        const mEnd = ('0' + dateEnd.getMinutes()).slice(-2);
        const time24Start = `${hStart}:${mStart}`
        const time24End = `${hEnd}:${mEnd}`
        return `${dateStart.getDate()} ${month} ${time24Start} - ${time24End}`;
    }

    useEffect(() => {
        setValue(props.course.course_attend_url && props.course.course_attend_url)
    })
    return  <GridItem
        w='100%'
        h='auto'
        boxShadow={'xl'}
        borderWidth={2}
        borderColor={'white'}
        borderRadius={7}
        position={'relative'}
    >
    <Box p='5' bgColor='white'>
        <Stack spacing='4'>
            <Flex flexDirection={{base: 'row'}} align={'flex-start'} justify={'space-between'}>
                <Box>
                    <Heading color={'black'} as='h3' size='md'>{props.course.course_name}</Heading>
                    <HStack>
                        <Text fontSize={'xs'}>รหัส: </Text>
                        <Text fontSize={'xs'}>{props.course.course_code}</Text>
                    </HStack>
                </Box>
                
            </Flex>
            <Divider/>
            <HStack>
                <Icon as={FiClock}/>
                <Badge ml='1' variant='outline'>
                    <Text fontSize={'md'}
                            color={'black'}>{showFormateTimeRange(props.course.course_airtime_list[0])}</Text>
                </Badge>
            </HStack>

            <Stack w='250px' noOfLines={2}>
                <Text color={'black'} noOfLines={4}>{props.course.course_desc}</Text>
            </Stack>
            {/* <Text color={'black'}>เรียน: <b>4</b> ครั้ง</Text> */}
            <Box>
                <Text>อาจารย์ผู้สอน</Text>
                <Box pl={{base:0, md:5}}>
                    {
                        props.course.course_teacher_list && 
                        props.course.course_teacher_list.map((teacher:any, idx:number) => {
                            return <Text key={idx}>{teacher.teacher_name}</Text>
                        })
                    }
                </Box>
            </Box>
            <Box>
                <Text>ช่องทางการเรียน</Text>
                <Box pl={{base:0, md:5}}>
                    <Stack spacing='2'>
                        <Box>
                            <Stack direction="row" align={'center'} justify='flex-start'>

                                {/* <Text>{course.course_attend_url_type}</Text> */}
                                {
                                    trainIcon &&
                                    trainIcon.map((val:{title:string, icon:any}, idx:number) => {
                                        var icon;
                                        if(val.title === props.course.course_attend_url_type) {
                                            icon = val.icon;
                                        }
                                        return icon;
                                    } )
                                }
                                <ChakraLink href={props.course.course_attend_url} target={'_blank'}>Open {props.course.course_attend_url_type}</ChakraLink>
                            </Stack>
                        </Box>
                        <Button w='100%' onClick={onCopy} colorScheme={'twitter'}>{hasCopied ? "Copied!" : "(หรือ) Copy Link"}</Button>
                    </Stack>
                    
                    
                </Box>
            </Box>

        </Stack>
    </Box>
    </GridItem>
}

const CourseList = (props:any) => {
    // console.log(props.courseList);
    return (
        <>
            <Grid templateColumns={{base:'repeat(1, 1fr)', md:'repeat(2, 1fr)', xl:'repeat(4, 1fr)'}} gap={4}>
                {props.courseList && 
                    props.courseList.map((val:any, idx:number) => {
                        return <CourseItem  key={idx} course={val} />
                    })
                }
            </Grid>
        </>
    )
}

const MyCourse = (props:any) => {
    const { userData, uid, user } = useOutletContext<outletContext>();
    const [userCourse, setUserCourse] = useState<any>();
    const courseSkillType = [
        { courseType: "L", courseTypeText: "ฟัง", active:true },
        { courseType: "S", courseTypeText: "พูด", active:true  },
        { courseType: "R", courseTypeText: "อ่าน", active:true  },
        { courseType: "W", courseTypeText: "เขียน", active:true  },
    ]
    const {
        data: dataAllCourse,
        error: errorSWRAll,
        isLoading: lodingAll,
        mutate: mutateAllCourse,
      } = useSWR("/courses/all", getAllCourse);
    const {
        data: dataUserCourse,
        error: errorSWRUserCourse,
        isLoading: loadingUserCourse,
        mutate: mutateUserCourse,
      } = useSWR(
        user ? { url: "/courses/my-course", user: user } : null,
        getUserCourse
      );
    
    useEffect(() => {
        // console.log(dataAllCourse, "dataAllCourse")
        // console.log(dataUserCourse, "dataUserCourse")
        let arr = [];
        if(dataUserCourse) {
            if(dataUserCourse.course_code_list.length > 0) {
                for(let item of dataUserCourse.course_code_list) {
                    // console.log(dataAllCourse);
                    const course:courseType = dataAllCourse?.course_list.find((val:courseType) => val.course_code === item);
                    arr.push(course);
                }
            }
            
        }
        setUserCourse(arr);
        // console.log("Hello Wolrd")
    }, [lodingAll, loadingUserCourse])

    // console.log(userCourse, 'userCourse');
    return <>
        <Box p={{base:'3', md:'7'}}>
            <Stack spacing='3'>
                <Flex align='center' justify='flex-end'>
                    <DateTimeShow />
                </Flex>
                <Box>
                    {userCourse &&
                        userCourse.length ?  
                        <CourseList courseList={userCourse} /> :
                        <Box bgColor='white' p='3' borderRadius={5} boxShadow={'md'}>
                            <Center>
                                <Text>ไม่มีคอร์สเรียน</Text>
                            </Center>
                        </Box>
                    }
                    
                </Box>
            </Stack>
        </Box>
    </>
}

export default MyCourse;