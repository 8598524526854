import React from 'react';
import { Text, Box, Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {articleList} from '../home/variables'
import { useNavigate } from 'react-router-dom';
import ShowPdf from './components/ShowPdf';
const Article = () => {
    const param = useParams();
    const [fileName, setFileName] = React.useState<string>();
    const [articleId, setArticleId] = React.useState<string>();
    const navigate = useNavigate();
    React.useEffect(() => {
        const article = articleList.find(val => val.id === param.id);
        if(article) {
            setArticleId(article.id);
            setFileName(article.fileName);
        } else {
            navigate(`/`, {replace: true});
        }
        
    }, [])
    return <Box>
        <ShowPdf fileName={`../pdf/${fileName}`} articleId={articleId} />
    </Box>
}

export default Article;