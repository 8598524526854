import React, { FC, useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { App } from "./App";

import TeacherSignIn from "./pages/auth/TeacherSignIn";
import TeacherSignUp from "./pages/auth/TeacherSignUp";
import AuthLayout from "./pages/auth/AuthLayout";
import TeacherForgetPassword from "./pages/auth/TeacherForgetPassword";
import Home from "./pages/home/HomePage";
import HomeLayout from "./pages/home/Layout";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase/config";
import { Box, Center, Fade, Spinner, useDisclosure } from "@chakra-ui/react";
import HomeIL from "./pages/home/HomeIL";
import UserSelection from "./pages/auth/UserSelection";
import StudentSignIn from "./pages/auth/StudentSignIn";
import StudentForgetPassword from "./pages/auth/StudentForgetPassword";
import StudentSignUp from "./pages/auth/StudentSignUp";
import Booking from "./pages/home/Booking";
import TestComp from "./pages/home/TestComp";
import Course from "./pages/home/course/Course";
import PersonalInfo from "./pages/home/PersonalInfo";
import LandingPage from "./pages/LandingPage/Index";
import Article from "./pages/LandingPage/Article";
import LayoutUser from "./pages/home/LayoutUser";
import MyCourse from "./pages/home/course/MyCourse";
import CourseHistory from './pages/home/course/CourseHistory'
import UserProfile from './pages/home/user/UserProfile';
import EmailVerify from "./pages/home/EmailVerify";
import TestPage from './pages/LandingPage/TestPage'

interface OwnProps {}

type Props = OwnProps;

const AppRouter: FC<Props> = (props) => {
	const [user, loading, error] = useAuthState(auth);
	const navigate = useNavigate();
	const location = useLocation();
	// const [load, setLoad] = React.useState<boolean>(true);
	useEffect(() => {
		// console.log(process.env);
		// console.log(user);
		// setTimeout(() => setLoad(false), 5000)
		if (loading) return;
		
		// console.log(user, "approute");
		// if(location.pathname == "/") navigate("/student", {replace: true}); // set routing to student only
		// if(!user) navigate("/", {replace: true});
		
		if (user) {
			// console.log(user.emailVerified)
			if(!user.emailVerified) {
				navigate("/verify-email", { replace: true });
			} else {

				navigate("/app", { replace: true });
			}
		} 
	}, [user, loading]);

	// useEffect(() => {
	//     if (loading) {
	//         onToggle();
	//     }
	// }, [loading]);

	return (
		<>
			<Routes>
				{/* <Route path="/" element={<UserSelection/>}/> */}
				<Route path="/">
					<Route index element={<LandingPage loading={loading} />} />
					<Route path="article/:id" element={<Article />} />
				</Route>

				

				{/*=== ส่วนสมัครครู ===*/}
				{/* <Route path="/teacher" element={<AuthLayout />}>
					<Route index element={<TeacherSignIn />} />
					<Route path="signin" element={<TeacherSignIn />} />
					<Route path="signup" element={<TeacherSignUp />} />
					<Route path="forgetpassword" element={<TeacherForgetPassword />} />
					<Route path="test" element={<TestComp />} />
				</Route> */}

				{/*=== ส่วนสมัครนักเรียน ===*/}
				{/* <Route path="student" element={<AuthLayout />}>
					<Route index element={<StudentSignIn />} />
					<Route path="signin" element={<StudentSignIn />} />
					<Route path="signup" element={<StudentSignUp />} />
					<Route path="forgetpassword" element={<StudentForgetPassword />} />
				</Route> */}
				{/* <Route path='/testpage' element={<TestPage />} /> */}
				<Route path='/verify-email' element={<EmailVerify />} />
				{/*=== ส่วนนักเรียน ===*/}
				<Route path="/app" element={<LayoutUser />} >
					<Route index element={<Course />}/> 
					<Route path='course' element={<Course />} />
					<Route path='my-course' element={<MyCourse />} />
					<Route path="course-hist" element={<CourseHistory />} />
					{/* <Route path="user-profile" element={<UserProfile />} /> */}
				</Route>

				
				{/* <Route path='/app' element={<HomeLayout />}>
                    <Route index element={<HomeIL />}/>
                    <Route path='home' element={<HomeIL />}/>
                    <Route path='booking' element={<Booking />}/>
                    <Route path='my-course' element={<Course />}/>
                    <Route path='personal-info' element={<PersonalInfo />}/>
                </Route> */}
			</Routes>
			{/* {loading && (
				<Box position="absolute" bg="white" w="full" h='full'>
					<Center h="100vh">
						<Spinner color="orange" size="xl" />
					</Center>
				</Box>
			)} */}
		</>
	);
};

export default AppRouter;
