import React from 'react';
import {
    Button,
    Heading,
    Text,
    Center,
    Flex,
    Stack,
    Box,
    useToast
} from '@chakra-ui/react'
import { logout } from '../../firebase/config';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/config";
import { sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { UserCredential, User } from 'firebase/auth';

const EmailVerify = (props:any) => {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = React.useState<User>()
    const [time, setTime] = React.useState<number>(0);
    const [timeActive, setTimeActive] = React.useState<boolean>(false)
    
    const toast = useToast()

        
    const verify = () => {
        if(time === 0) {
            sendEmailVerification(currentUser as User)
                .then(() => {
                    console.log('send');
                        setTime(60);
                        setTimeActive(true);
                        toast({
                            title: `ส่งอีเมลเรียบร้อยแล้ว!`,
                            position: 'top',
                            isClosable: true,
                            status: 'success',
                            
                        })


                    // history.push('/verify-email')
                }).catch((err) => {
                    console.log(err, 'err')
                    setTime(60);
                    setTimeActive(true);
                    toast({
                        title: `ส่งอีเมลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง!`,
                        position: 'top',
                        isClosable: true,
                        status: 'error',
                        
                    })
                })
        }
    }

    React.useEffect(() => {
        console.log('time')
        let interval = setInterval(() => {
            if(time > 0) {
                setTime(time - 1);
            } else {
                setTimeActive(false);
            }

            // reload check verify
            if(user?.emailVerified) {
                navigate('/app', {replace: true})
            } else {
                user?.reload();
            }
             
        }, 1000)
        return () => clearInterval(interval);
    }, [time])

    React.useEffect(() => {
        // let interval = setInterval(() => {
        //     if(time > 0) setTime(time - 1);
        // }, 1000)
        if (loading) return;
        if(user) {
            setCurrentUser(user);   
            if(currentUser) verify();
        } else {
            navigate('/', {replace: true});
        }
        // return () => clearInterval(interval);
    }, [user, loading, currentUser])

    return (
        <Flex 
            direction="column" flex="1"
            minH={{base: 'auto', md: '100vh'}}
            backgroundImage={'./beams.jpg'} backgroundPosition="center"
            backgroundSize="cover"
            >
                    <Center minH={{base: 'auto', md: '100vh'}}>
                        <Box bg='white' p='10' boxShadow='md'>
                            <Stack spacing='5'>
                                <Center>
                                    <Heading size='md'>กรุณายืนยันอีเมล์</Heading>
                                </Center>
                                <Center>
                                    <Text fontWeight={'bold'}>อีเมลยืนยันถูกส่งไปที่</Text>
                                    {/* <Text fontWeight={'bold'}>A Verification email has been sent to</Text> */}
                                </Center>
                                <Center>
                                    <Text>{currentUser?.email}</Text>
                                </Center>
                                <Center>
                                    <Text>กรุณาตรวจสอบอีเมล์ เพื่อยืนยันการเข้าใช้งาน</Text>
                                </Center>
                                <Flex direction={'row'} align='center' justify='space-between'>
                                    <Button
                                        isDisabled={timeActive}
                                        onClick={verify}>ส่งอีกครั้ง {time > 0 && `(${time})`}</Button>
                                    <Button colorScheme={'red'} onClick={logout}>ออกจากระบบ</Button>
                                </Flex>
                            </Stack>
                        </Box>
                    </Center>
        </Flex>
    )
}

export default EmailVerify;