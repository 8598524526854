import {ChakraProvider, ColorModeScript, extendTheme} from '@chakra-ui/react';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import './index.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import '@fontsource/inter/variable.css';
import AppRouter from './AppRouter';
import AppTheme from './AppTheme';
import TestRouter from './TestRouter';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
    <React.StrictMode>
            <ChakraProvider theme={AppTheme}>
                <BrowserRouter>
                    <ColorModeScript initialColorMode={AppTheme.config.initialColorMode}/>
                    <AppRouter/>
                    {/* <TestRouter /> */}
                </BrowserRouter>
            </ChakraProvider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

