import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box
} from '@chakra-ui/react'
import { LoremIpsum } from 'react-lorem-ipsum';
import React, { useEffect, useRef, useState } from 'react';

const ModalBegin = (props: any) => {
useEffect(() => {

}, []); 
  // const { isOpen, onOpen, onClose } = useDisclosure()
return (
  <>
    {/* <Button onClick={props.onOpen}>Open Modal</Button> */}
    <Modal 
      isOpen={props.isOpen} 
      onClose={props.onClose} 
      size={props.size ? props.size : 'xl'}
      scrollBehavior="inside"
      closeOnOverlayClick={typeof(props.closeOnOverlayClick) !== 'undefined' ? props.closeOnOverlayClick : true}
      >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.Title && props.Title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            {props.contentBody ?
                props.contentBody :
                <LoremIpsum p='2' />
            }
        </ModalBody>

        <ModalFooter>
          {props.contentFooter && 
            props.contentFooter 
          }
          {/* <Button colorScheme='blue' mr={3} onClick={props.onClose}>
            Close
          </Button>
          <Button variant='ghost'>Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
)

}

export default ModalBegin;