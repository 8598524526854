import React, {useState, useEffect} from 'react';
import { Heading, HeadingProps } from '@chakra-ui/react';
import moment from 'moment';
import 'moment/locale/th';

export default function DateTimeShow(props:HeadingProps) {
    const [date, setDate] = useState<string>();
    const [timer, setTimer] = useState<string>();
    const tickInterval = setInterval(tick, 1000);
    // const [tickInterval, setTickInterval] = useState();
    useEffect(() => {
        let year = parseInt(moment().format('YYYY')) + 543;
        setDate(moment().format(`[วัน]dddd [ที่] D [เดือน]MMMM [ปี] ${year}`).toString())
        
    }, [])
    useEffect(() => {
        
        return () => {
            clearInterval(tickInterval);
        };
    }, [tickInterval])
    
    function tick() {
        setTimer(moment().format('H:mm:ss'));
    }

    return <Heading as='h2' size='md' {...props} >{date} {timer ? `เวลา ${timer} น.` : ''}</Heading>
}