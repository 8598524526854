type DDLCenterModel = {
    text: string,
    value: string,
}[];

export const menuList: { title: string, link: string }[] = [
    {
        title: 'Home',
        link: 'home'
    },
    {
        title: 'จองคอร์ส',
        link: 'booking'
    },
    {
        title: 'ประวัติ',
        link: 'my-course'
    }, 
    {
        title: "ประวัติส่วนตัว",
        link:"personal-info"
    }
];

export const landingPageMenuList: {title:string, link: string}[] = [
    {
        title:'จองคอร์สเรียน',
        link:'#booking-course'
    },
    {
        title:'คุณครู',
        link:'#teacher'
    },
    {
        title:'คู่มือการใช้งาน',
        link:'#how-to-use'
    },
    {
        title:'บทความ',
        link:'#article'
    },
    {
        title:'คำถามที่พบบ่อย',
        link:'#question'
    }
]

export const schoolList: DDLCenterModel = [
    {
        text: "มหาวิทยาลัยราชภัฏอุบลราชธานี",
        value:'UBRU'
    },
    {
        text: "มหาวิทยาลัยราชภัฏภูเก็ต",
        value:'PKRU'
    },
    {
        text: "มหาวิทยาลัยราชภัฏยะลา",
        value:'YRU'
    },
]


export const branchList: DDLCenterModel = [
    {
        text: 'ปฐมวัย',
        value: 'b1'
    },
    {
        text: 'ประถมศึกษา',
        value: 'b2'
    },
]

export const yearList: DDLCenterModel = [
    {
        text:"ปี 1",
        value:'1'
    },
    {
        text:"ปี 2",
        value:'2'
    },
    {
        text:"ปี 3",
        value:'3'
    },
    {
        text:"ปี 4",
        value:'4'
    },
    {
        text:"ปี 5",
        value:'5'
    }
]

type articleType = {
    title:string,
    fileName:string,
    id:string
}[];

export const articleList:articleType = [
    {
        title:"Noun คำนาม",
        fileName:"1.Noun คำนาม.pdf",
        id:"1"
    },
    {
        title:"Article คำนำหน้าคำนาม",
        fileName:"2.Article คำนำหน้าคำนาม.pdf",
        id:"2"
    },
    {
        title:"Pronouns คำสรรพนาม",
        fileName:"3.Pronouns คำสรรพนาม.pdf",
        id:"3"
    },
    {
        title:"Verb คำกริยา",
        fileName:"4.Verb คำกริยา.pdf",
        id:"4"
    },
    {
        title:"Adjective คำคุณศัพท์",
        fileName:"5.Adjective คำคุณศัพท์.pdf",
        id:"5"
    },
    {
        title:"Comparison of Adjectives การเปรียบเทียบคำคุณศัพท์",
        fileName:"6.Comparison of Adjectives การเปรียบเทียบคำคุณศัพท์.pdf",
        id:"6"
    },
    {
        title:"Adverb คำกริยาวิเศษณ์",
        fileName:"7.Adverb คำกริยาวิเศษณ์.pdf",
        id:"7"
    },
    {
        title:"หลักการเปลี่ยน Adjective เป็น Adverb",
        fileName:"8.หลักการเปลี่ยน Adjective เป็น Adverb.pdf",
        id:"8"
    },
    {
        title:"Preposition คำบุพบท",
        fileName:"9.Preposition คำบุพบท.pdf",
        id:"9"
    },
    {
        title:"Conjunction คำเชื่อม",
        fileName:"10.Conjunction คำเชื่อม.pdf",
        id:"10"
    },
    {
        title:"Tense",
        fileName:"11.Tense.pdf",
        id:"11"
    },
    {
        title:"Present Tense",
        fileName:"12.Present Tense.pdf",
        id:"12"
    },
    {
        title:"Past Tense",
        fileName:"13.Past Tense.pdf",
        id:"13"
    },
    {
        title:"Future Tense",
        fileName:"14.Future Tense.pdf",
        id:"14"
    },
    {
        title:"Subject-Verb Agreement",
        fileName:"15.Subject-Verb Agreement.pdf",
        id:"15"
    },
    {
        title:"There is  There are",
        fileName:"16.There is  There are.pdf",
        id:"16"
    },
    {
        title:"การใช้ Have  Has  Had",
        fileName:"17.การใช้ Have  Has  Had.pdf",
        id:"17"
    },
    {
        title:"Wh-question",
        fileName:"18.Wh-question.pdf",
        id:"18"
    },
    {
        title:"การใช้ How",
        fileName:"19.การใช้ How.pdf",
        id:"19"
    },
    {
        title:"conversation บทสนทนา",
        fileName:"20.conversation บทสนทนา.pdf",
        id:"20"
    },
    {
        title:"Guessing word meaning การเดาความหมายศัพท์",
        fileName:"21.Guessing word meaning การเดาความหมายศัพท์.pdf",
        id:"21"
    },
    {
        title:"v.3 ช่องที่ควรรู้",
        fileName:"22.v.3 ช่องที่ควรรู้.pdf",
        id:"22"
    },
    {
        title:"Prefix",
        fileName:"23.Prefix.pdf",
        id:"23"
    },
    {
        title:"Suffix",
        fileName:"24.Suffix.pdf",
        id:"24"
    },
    {
        title:"vocab1",
        fileName:"25.vocab1.pdf",
        id:"25"
    },
    {
        title:"vocab2",
        fileName:"26.vocab2.pdf",
        id:"26"
    },
    {
        title:"vocab3",
        fileName:"27.vocab3.pdf",
        id:"27"
    },
    {
        title:"vocab4",
        fileName:"28.vocab4.pdf",
        id:"28"
    },
    {
        title:"vocab5",
        fileName:"29.vocab5.pdf",
        id:"29"
    },
    {
        title:"Occupation อาชีพ",
        fileName:"30.Occupation อาชีพ.pdf",
        id:"30"
    },
    {
        title:"Human body",
        fileName:"31.Human body.pdf",
        id:"31"
    }
]