import React, { useState, useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
function AlertDialogCustom(props: any) {
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const {
    dialogHeaderContent,
    dialogBodyContent,
    dialogButtonContent,
    dialogCancelRef,
    isOpen,
    onOpen,
    onClose,
    courseCode,
  } = props;

  useEffect(() => {
  });
  
  return (
    <>
      {/* <Button onClick={onOpen}>Discard</Button> */}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={!dialogCancelRef ? cancelRef : dialogCancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {dialogHeaderContent && dialogHeaderContent}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {dialogBodyContent && dialogBodyContent}
          </AlertDialogBody>
          <AlertDialogFooter>
            {!dialogButtonContent ? (
              <>
                <Button ref={cancelRef} onClick={onClose}>
                  OK
                </Button>
              </>
            ) : (
              dialogButtonContent
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default AlertDialogCustom;
