import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { Box, Button, Stack } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const App = (props: any) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
			
				<Viewer
					fileUrl={"../pdf/1.Noun คำนาม.pdf"}
					httpHeaders={{
						"accept": "application/pdf"
					}}
					// onDocumentLoad={(e) => console.log(e)}
					
				/>
			
		</Worker>
	);
};

const ShowPdf = (props: any) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
	return (
		<Stack spacing={'5'} py={{base: 0, md:"50px"}} px={{base:0, md:"100px"}}>
            <Box w='100%'>
                <Link to={'/#article' + props.articleId}>
                    <Button w='100%' colorScheme={'red'}>
                        กลับหน้าหลัก
                    </Button>
                </Link>
            </Box>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    
                    <Viewer
                        fileUrl={props.fileName && props.fileName}
						
                        plugins={[
                            // Register plugins
                            defaultLayoutPluginInstance,
                        ]}
                    />
                
            </Worker>
		</Stack>
	);
};

const ShowPdfx = () => {
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [numPages, setNumPages] = useState<number>();
	const onDocumentLoadSuccess = (d: any) => {
		console.log(d);
	};
	return (
		<div>
			<div style={{ width: 600 }}>
				<Document
					file="./pdf/1_Noun_คำนาม.pdf"
					onLoadSuccess={onDocumentLoadSuccess}
				>
					<Page pageNumber={pageNumber} width={600} />
				</Document>
			</div>

			{/* <p>
				Page {pageNumber} of {numPages}
			</p> */}
		</div>
	);
};

export default ShowPdf;
