import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  Input,
  Stack,
  Text,
  Flex,
  Box,
  Heading,
  Image
} from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { Logo } from './Logo'

export const FooterX = () => (
  <Container as="footer" role="contentinfo">
    <Divider borderColor="gray.500"/>

    <Flex flexDirection={{base:"column", md:'row'}} pt={4}>
      <Stack spacing='3' w='100%' px={{base:"5"}} py={{base:"5"}}>
        <Box>
          <Image src='./images/uniclass_logo_crop.png' w='64' />
        </Box>
        {/*<Text>*/}
        {/*  องค์กรพัฒนาการศึกษาและจัดสรร <br />*/}
        {/*  ติวเตอร์ตัวต่อตัวให้กับผู้เรียน โดย <br />*/}
        {/*  ติวเตอร์คุณภาพจากสถาบันการ <br />*/}
        {/*  ศึกษาชั้นนำ ของประเทศ*/}
        {/*</Text>*/}
      </Stack>
      <Box w='100%' px={{base:"5"}} py={{base:"5"}}>
        <Heading size={'xl'}>
          เกี่ยวกับเรา
        </Heading>
        {/*<Text>เกี่ยวกับเรา</Text>*/}
        <Text>
          องค์กรพัฒนาการศึกษาและจัดสรร <br />
          ติวเตอร์ตัวต่อตัวให้กับผู้เรียน โดย <br />
          ติวเตอร์คุณภาพจากสถาบันการ <br />
          ศึกษาชั้นนำ ของประเทศ
        </Text>
      </Box>
      <Box w='100%' px={{base:"5"}} py={{base:"5"}}>
        <Heading size="xl">
          ติดต่อเรา
        </Heading>
        <Text>
          <Text as={'b'}>เลขที่</Text>&nbsp;0105564020005 <br />
          <Text as={'b'}>ชื่อบริษัท</Text>&nbsp;บริษัท สเปซบี จำกัด <br />
          เลขที่ 171/1 ซอยโชคชัย 4 ลาดพร้าว 53 <br />
          แขวงสะพานสอง เขตวังทองกลาง <br />
          กรุงเทพมหานคร 10310 <br />
          <Text as={'b'}>โทร.</Text>&nbsp;089-656-2326
        </Text>
      </Box>
    </Flex>
    {/* 
    <Stack
      spacing="8"
      direction={{ base: 'column', md: 'row' }}
      justify="space-between"
      py={{ base: '12', md: '16' }}
    >
      <Stack spacing={{ base: '6', md: '8' }} align="start">
        <Text>Logo</Text>
        <Text color="muted">
          องค์กรพัฒนาการศึกษาและจัดสรร <br />
          ติวเตอร์ตัวต่อตัวให้กับผู้เรียน โดย <br />
          ติวเตอร์คุณภาพจากสถาบันการ <br />
          ศึกษาชั้นนำ ของประเทศ
        </Text>
      </Stack>
      <Stack
        direction={{ base: 'column-reverse', md: 'column', lg: 'row' }}
        spacing={{ base: '12', md: '8' }}
      >
        <Stack direction="row" spacing="8">
          <Stack spacing="4" w="100%"  flex="1">
            <Text fontSize="sm" fontWeight="semibold" color="subtle">
              Product
            </Text>
            <Stack spacing="3" shouldWrapChildren>
              <Button variant="link">How it works</Button>
              <Button variant="link">Pricing</Button>
              <Button variant="link">Use Cases</Button>
            </Stack>
          </Stack>
          <Stack spacing="4" w="100%"  flex="1">
            <Text fontSize="sm" fontWeight="semibold" color="subtle">
              Legal
            </Text>
            <Stack spacing="3" shouldWrapChildren>
              <Button variant="link">Privacy</Button>
              <Button variant="link">Terms</Button>
              <Button variant="link">License</Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
    */}
    <Divider borderColor="gray.500" />
    <Stack
      pt="8"
      pb="12"
      justify="space-between"
      direction={{ base: 'column-reverse', md: 'row' }}
      align="center"
    >
      <Text fontSize="sm" color="subtle">
        &copy; {new Date().getFullYear()} Develop by Uniclass.
        {/* Chakra UI Pro, Inc. All rights reserved. */}
      </Text>
        <Text fontSize='sm' color='subtle'>App Version: {process.env.REACT_APP_VERSION}</Text>
      {/* <ButtonGroup variant="ghost">
        <IconButton
          as="a"
          href="#"
          aria-label="LinkedIn"
          icon={<FaLinkedin fontSize="1.25rem" />}
        />
        <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />
        <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} />
      </ButtonGroup> */}
    </Stack>
  </Container>
)
