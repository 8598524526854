import {initializeApp} from 'firebase/app';
import {
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    sendEmailVerification,
    updatePassword,
    User,
    reauthenticateWithCredential,
    EmailAuthProvider,
} from 'firebase/auth';
import {getFirestore, query, getDocs, collection, where, addDoc, doc, setDoc} from 'firebase/firestore';
import {changeUserPasswordOnFireStore} from '../firestore/collection';
import {getPerformance} from 'firebase/performance';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// const firebaseConfig = {
//     apiKey: "AIzaSyCZV_ssivPXyDsml6af-uET3DaLQn6eygs",
//     authDomain: "krurakthin-a2b97.firebaseapp.com",
//     projectId: "krurakthin-a2b97",
//     storageBucket: "krurakthin-a2b97.appspot.com",
//     messagingSenderId: "128816468087",
//     appId: "1:128816468087:web:575f57e37dd35220827672",
//     measurementId: "G-W77EPVMDRX"
// };

const firebaseConfigTest = {
    apiKey: "AIzaSyBft1FWxXAPzDaor6vOl6P76yzCsANBKAI",
    authDomain: "uniclass-testapp.firebaseapp.com",
    projectId: "uniclass-testapp",
    storageBucket: "uniclass-testapp.appspot.com",
    messagingSenderId: "560083681327",
    appId: "1:560083681327:web:1f189327da4f64a54c46da"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const perf = getPerformance(app);


// onAuthStateChanged(auth, (user) => {
//     if (user) {
//         // User is signed in, see docs for a list of available properties
//         // https://firebase.google.com/docs/reference/js/firebase.User
//         const uid = user.uid;
//         alert(uid)
//         // ...
//     } else {
//         // User is signed out
//         // ...
//         alert('User is signed out')
//     }
// });
type SignUpType2 = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    institution: string;
    major: string;
    yearClass: string;
    role: boolean;
    offerPromotion: boolean;
    type: string;
    credit: number;
}

type SignUpType = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    school: string;
    branch: string;
    year: string;
    role: boolean;
    offerPromotion: boolean;
    type: string;
    credit: number;
}

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, 'users'), where('uid', '==', user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, 'users'), {
                uid: user.uid,
                name: user.displayName,
                authProvider: 'google',
                email: user.email,
            });
        }
    } catch (err: any) {
        console.error(err);
        // alert(err.message);
        throw err;
    }
};
const logInWithEmailAndPassword = async (email: string, password: string) => {
    try {
        // console.log(email, password)
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err: any) {
        console.error(err);
        // alert(err.message);
        throw err;
    }
};

const logInWithEmailAndPassword2 = async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password);

};

const registerWithEmailAndPassword = async (name: string, email: string, password: string, data: SignUpType | SignUpType2) => {

    try {
        let obj = data.type === 'student' ? {student: {...data}} : {};
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;

        sendEmailVerification(res.user);
        await setDoc(doc(collection(db, 'users'), user.uid),
            {
                uid: user.uid,
                name,
                authProvider: 'local',
                email,
                ...obj
            });
        await setDoc(doc(collection(db, '_users_courses'), `U:${user.uid}:COURSE`),
            {
                course_code_list: [],
                uid: user.uid,
            }
        );
        return {status: true};
    } catch (err: any) {

        return {status: false, error: err};
    }
    /*
    return false;
    try {
        let obj = data.type === 'student' ? {student: {...data}} : {};
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await setDoc(doc(collection(db, 'users'), user.uid),
            {
                uid: user.uid,
                name,
                authProvider: 'local',
                email,
                ...obj
            });
    } catch (err: any) {
        console.error(err);
        // alert(err.message);
        throw err;
    }
    */
};
/*
const registerTest = async () => {
    await setDoc(doc(collection(db, "userTest"), "Ada"),
        {
            first: "Ada",
            last: "Lovelacxe",
            born: 1815
        }).then(() => {
        console.log('register success')
    })
        .catch((err) => console.log(err, 'catch'));

}

const registerTestLoop = async () => {
    try {
        await setDoc(doc(collection(db, "userTest"), "Ada"),
            {
                first: "Ada",
                last: "Lovelace",
                born: 1815
            })
        return {status: true}
    } catch (err) {
        console.log(err);
        // throw err;
        return {status: false}
    }


}
*/
const sendPasswordReset = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert('Password reset link sent!');
    } catch (err: any) {
        console.error(err);
        // alert(err.message);
        throw err;
    }
};

const sendPasswordReset2 = async (email: string) => {
    var result = {
        status: false,
        message: '',
        code: '',
    };
    try {
        await sendPasswordResetEmail(auth, email);
        // alert("Password reset link sent!");
        result.status = true;
        result.message = 'ส่งอีเมลสำเร็จแล้ว';

    } catch (err: any) {
        // console.error(err);
        // alert(err.message);
        // throw err;
        result.status = false;
        console.log(err);
        switch (err.code) {
            case 'auth/user-not-found':
                result.message = 'ไม่มีอีเมลนี้ในระบบ';
                break;
            case 'auth/invalid-email':
                result.message = 'อีเมลผิดพลาด';
                break;
            default:
                result.message = '';
        }
    }
    return result;
};

const logout = () => {
    signOut(auth).then(() => {
        console.log('signout');
    }).catch((error) => {
        console.log(error);
    });
};

const changePassword = async (currentUser: User, oldPassword: string, newPassword: string) => {
    // const currentUser = await auth.currentUser;
    // console.log(currentUser);

    // var user = await auth.currentUser;
    // var credentials = firebase.auth.EmailAuthProvider.credential('puf@firebaseui.com', 'firebase');
    // currentUser.reload();

    const result = {
        status: false,
        message: ''
    };

    var credentials = EmailAuthProvider.credential(currentUser.email as string, oldPassword);
    await reauthenticateWithCredential(currentUser, credentials);
    // return false;
    if (currentUser) {
        await updatePassword(currentUser, newPassword).then(() => {
            // console.log('success');
            changeUserPasswordOnFireStore(currentUser.uid, newPassword);
            result.status = true;
            result.message = 'เปลี่ยนรหัสผ่านสำเร็จ';
        }).catch((err) => {
            console.log(err);
            result.status = false;
            result.message = 'เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ';
        });
    }
    return result;
};
const changePassword2 = async (currentUser: User, oldPassword: string, newPassword: string) => {
    // const currentUser = await auth.currentUser;
    // console.log(currentUser);

    // var user = await auth.currentUser;
    // var credentials = firebase.auth.EmailAuthProvider.credential('puf@firebaseui.com', 'firebase');
    // currentUser.reload();

    const result = {
        status: false,
        message: ''
    };

    var credentials = EmailAuthProvider.credential(currentUser.email as string, oldPassword);

    await reauthenticateWithCredential(currentUser, credentials).then(async (value) => {
        
    
        await updatePassword(currentUser, newPassword).then(() => {
            // console.log('success');
            // changeUserPasswordOnFireStore(currentUser.uid, newPassword);
            console.log('success')
            result.status = true;
            result.message = 'เปลี่ยนรหัสผ่านสำเร็จ';
        }).catch((err) => {
            console.log(err, 'updatepassword2err');
            result.status = false;
            result.message = 'เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ';
        });
    }).catch((err) => {
        console.log(err, 'changepassword2 err')
        result.status = false;
        result.message = 'เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ';
    });

    // return false;
    if (currentUser) {
        await updatePassword(currentUser, newPassword).then(() => {
            // console.log('success');
            // changeUserPasswordOnFireStore(currentUser.uid, newPassword);
            result.status = true;
            result.message = 'เปลี่ยนรหัสผ่านสำเร็จ';
        }).catch((err) => {
            console.log(err);
            result.status = false;
            result.message = 'เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ';
        });
    }
    return result;
};

export {
    app,
    auth,
    db,
    signInWithGoogle,
    logInWithEmailAndPassword,
    logInWithEmailAndPassword2,
    registerWithEmailAndPassword,
    sendPasswordReset,
    sendPasswordReset2,
    logout,
    changePassword,
    changePassword2,
    // registerTest,
    // registerTestLoop
};
