import { auth } from './config'

class JsonResult {
    status;
    message
    constructor() {
        this.status = true
    }

    get status() {
        return this.status;
    }

    set status(status) {
        this.status = status;
    }


}

const validatePassword = (password, confirmPassword) => {
    let isValid = true;
    let message = "";
    if(password !== '' && confirmPassword !== '') {
        if(password !== confirmPassword) {
            isValid = false;
            message = "รหัสผ่านไม่ตรงกัน"
        }
    } else {
        isValid = false;
        message = "กรุณายืนยันรหัสผ่าน"
    }
    return {status: isValid, message : message}
}

export { 
    validatePassword
}