import {extendTheme, theme as baseTheme} from '@chakra-ui/react'
import * as components from './components'
import * as foundations from './foundations'
import {theme as defaultTheme} from '@chakra-ui/theme';
import {Textarea} from "./components";

export const theme: Record<string, any> = extendTheme({
    // ...defaultTheme,
    initialColorMode: 'light',
    useSystemColorMode: false,
    ...foundations,
    // ...defaultTheme,
    components: {
        ...components,
        Heading: {
            baseStyle: {
                color: 'emphasized' // gray.900
            }
        },
        Text: {
            baseStyle: {
                color: 'emphasized' // gray.900
            }
        },
        // Textarea: {
        //     baseStyle: {
        //         color: 'emphasized' // gray.900
        //     }
        // }
    },
    colors: {...baseTheme.colors, brand: baseTheme.colors.blue },
    space: {
        '4.5': '1.125rem',
    },

})
