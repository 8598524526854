import { initializeApp } from "firebase/app";
import {
	collection,
	query,
	where,
	getFirestore,
	setDoc,
	getDocs,
	addDoc,
	doc,
	getDoc,
	updateDoc,
	runTransaction,
} from "firebase/firestore";
import { app, db } from '../firebase/config';
import {
	userType,
	userStudentType,
	courseType
} from '../firestore'

const getAllCourse = async () => {
	const docRef = doc(db, "_courses", "C:*");
	const docSnap = await getDoc(docRef);
	// console.log("getAllCourse");

	if (docSnap.exists()) {
		// console.log("Document data:", docSnap.data());
		return docSnap.data();
	} else {
		// doc.data() will be undefined in this case
		// console.log("No such document!");
		return null;
	}
};

const getAllCourseATTD = async () => {
	const docRef = doc(db, "_courses", "C:*:ATTD");
	const docSnap = await getDoc(docRef);

	if (docSnap.exists()) {
		// console.log("Document data:", docSnap.data());
		return docSnap.data();
	} else {
		// doc.data() will be undefined in this case
		// console.log("No such document!");
		return null;
	}
};

const getCourseById = async () => {};

const getUserCourse = async (api:any) => {
//   console.log('Get User Course ####################################');
  const {uid} = api.user;
  const docRef = doc(db, "_users_courses", `U:${uid}:COURSE`);
  const docSnap = await getDoc(docRef);
  if(docSnap.exists()){
    return docSnap.data();
  } else {
    return null;
  }

};

const getUser = async(api:any) => {
	const docRef = doc(db, "users", api.user.uid)
	const docSnap = await getDoc(docRef);
	// console.log("get userxx")
	if(docSnap.exists()){
		return docSnap.data().student;
	} else {
		return null;
	}
}

type courseATTDType = {
	course_list: [
		{
			attend_list: [{ uid: string }];
			course_code: string;
		}
	];
};

type courseCodeATTDType = {
	attend_list: [{ uid: string }];
	course_code: string;
};

type userCourseType = {
	course_code_list: string[];
	uid: string;
};

const r = (i:number) => {
	return new Promise<any>( (resolve, reject) => {
		setTimeout(()=> {
				// console.log(i);
			if(i < 5) {
				resolve("xxx" + i);	
			} else {
				resolve("max" + i);	
			}
		}, 1000);
		
	});
}

const bookingCourse = async (userId: string, course_code: string) => {
	// console.log(userId, course_code);
	let courseATTD: courseATTDType,
		courseCodeATTD: courseCodeATTDType,
		userCourse: userCourseType,
		userData: any,
		courseList:any;
	// ref course limit 
	const courseRef = doc(db, "_courses", "C:*");
	// const courseSnap = await getDoc(courseRef);
	// if(courseSnap.exists()) {
	// 	courseList = courseSnap.data().course_list.find((val:any) => val.course_code === course_code);
	// } else {

	// }

	// const limit = courseList.course_attend_limit;
	// return {status:true};

		
		
	// ref Course
	// get C:*:ATTD
	const courseATTDRef = doc(db, "_courses", "C:*:ATTD");
	/*
	let courseATTDSnap = await getDoc(courseATTDRef);
	if (!courseATTDSnap.exists()) {
		let parentPath = courseATTDRef.parent.path;
		let id = courseATTDRef.id;
		await setDoc(doc(collection(db, parentPath), id), {
			course_code_list: [],
			uid: userId,
		});
		courseATTDSnap = await getDoc(courseATTDRef);
	}
	courseATTD = courseATTDSnap.data() as courseATTDType;
	*/
	// get C:{course_code}:ATTD
	const courseCodeATTDRef = doc(db, "_courses", `C:${course_code}:ATTD`);
	/*
	let courseCodeATTDSnap = await getDoc(courseCodeATTDRef);
	if (!courseCodeATTDSnap.exists()) {
		let parentPath = courseATTDRef.parent.path;
		let id = courseATTDRef.id;
		await setDoc(doc(collection(db, parentPath), id), {
			course_code_list: [],
			uid: userId,
		});
		courseCodeATTDSnap = await getDoc(courseCodeATTDRef);
	}
	courseCodeATTD = courseCodeATTDSnap.data() as courseCodeATTDType;
	*/

	// get _users_courses, U:{userId}:COURSE
	const userCourseRef = doc(db, "_users_courses", `U:${userId}:COURSE`);
	/*
	let userCourseSnap = await getDoc(userCourseRef);
	if (!userCourseSnap.exists()) {
		let refParentPath = userCourseRef.parent.path;
		let refId = userCourseRef.id;
		await setDoc(doc(collection(db, refParentPath), refId), {
			course_code_list: [],
			uid: userId,
		});
		userCourseSnap = await getDoc(userCourseRef);
		// userCourse = userCourseSnap.data();
	}
	userCourse = userCourseSnap.data() as userCourseType;
	*/
	// console.log(courseATTD, courseCodeATTD, userCourse)
	// console.log(courseATTD, "courseATTD");

	// courseATTD

	// Initialize document
	// const cityRef = doc(collection(db, "cities"), "SF");
	// setDoc(cityRef,
	//   {
	//     name: 'San Francisco',
	//     state: 'CA',
	//     country: 'USA',
	//     capital: false,
	//     population: 860000
	//   }
	// )

	// get user for update credit
	// const userRef = doc(collection(db, "userTestx"), userId);
	const userRef = doc(collection(db, "users"), userId);
	/*
	let userSnap = await getDoc(userRef);
	userData = userSnap.data();
	*/
	// userData.student
	try {
		await runTransaction(db, async (t) => {
			// const doc = await t.get(cityRef);
			const courseSnap = await t.get(courseRef);
			if(courseSnap.exists()) {
				courseList = courseSnap.data().course_list.find((val:any) => val.course_code === course_code);
			} else {
		
			}
		
			const limit = parseInt(courseList.course_attend_limit) - 1;
			
			let courseATTDSnap = await t.get(courseATTDRef);
			if (!courseATTDSnap.exists()) {
				let parentPath = courseATTDRef.parent.path;
				let id = courseATTDRef.id;
				await t.set(courseATTDRef, {
					course_code_list: [],
					uid: userId,
				});
				courseATTDSnap = await t.get(courseATTDRef);
			}
			courseATTD = courseATTDSnap.data() as courseATTDType;

			let courseCodeATTDSnap = await t.get(courseCodeATTDRef);
			if (!courseCodeATTDSnap.exists()) {
				let parentPath = courseATTDRef.parent.path;
				let id = courseATTDRef.id;
				await t.set(courseCodeATTDRef, {
					course_code_list: [],
					uid: userId,
				});
				courseCodeATTDSnap = await t.get(courseCodeATTDRef);
			}
			courseCodeATTD = courseCodeATTDSnap.data() as courseCodeATTDType;

			let userCourseSnap = await t.get(userCourseRef);
			if (!userCourseSnap.exists()) {
				let refParentPath = userCourseRef.parent.path;
				let refId = userCourseRef.id;
				await t.set(userCourseRef, {
					course_code_list: [],
					uid: userId,
				});
				userCourseSnap = await t.get(userCourseRef);
				// userCourse = userCourseSnap.data();
			}
			userCourse = userCourseSnap.data() as userCourseType;

			let userSnap = await t.get(userRef);
			userData = userSnap.data();
			// console.log(userData);
			// console.log(limit,'limit')
			// console.log(courseCodeATTD.attend_list.length,'courseCodeATTD.attend_list.length')
			if(courseCodeATTD.attend_list.length > limit) {
				throw 'maxLimit'
			}
			
			// t.update(courseATTDRef, {course_list: courseATTD.course_list })
			// throw 'x';
			// Add one person to the city population.
			// Note: this could be done without a transaction
			//       by updating the population using FieldValue.increment()
			
			// const docData: any = doc.data();
			// const newPopulation = (docData.population += 1);
			// console.log(newPopulation);
			// t.update(cityRef, { population: newPopulation });
			// update COURSE ATTD
			if (courseATTD.course_list.length) {
				// ถ้ามี length จะเช็ค course_code ถ้าไม่มีจะ add ใหม่
				let course = courseATTD.course_list.find(
					(val) => val.course_code === course_code
				);
				if (course) {
					course.attend_list.push({ uid: userId });
					Object.assign(courseATTD.course_list, course);
				} else {
					courseATTD.course_list.push({
						attend_list: [{ uid: userId }],
						course_code: course_code,
					});
					// console.log(courseATTD);
				}
			} else {
				// ถ้าไม่มี length
				courseATTD = {
					course_list: [
						{
							attend_list: [{ uid: userId }],
							course_code: course_code,
						},
					],
				};
			}
			t.update(courseATTDRef, {course_list: courseATTD.course_list })

			// update c:{course_code}:attd
			courseCodeATTD.attend_list.push({ uid: userId });
			t.update(courseCodeATTDRef, {attend_list: courseCodeATTD.attend_list});

			// update userCourse
			userCourse.course_code_list.push(course_code);
			t.update(userCourseRef, {course_code_list: userCourse.course_code_list});

			// update user credit
			userData.student.credit -= 1;
			t.update(userRef, {student: userData.student});
			
			
		});
		console.log("Transaction success!");
		return {status:true};
	} catch (e) {
		console.log("Transaction failure:", e);
		return {status:false};
	}
};

const bookingCourseTest = async (userId: string, course_code: string) => {
	// console.log(userId, course_code);
	let courseATTD: courseATTDType,
		courseCodeATTD: courseCodeATTDType,
		userCourse: userCourseType,
		userData: any,
		courseList:any;
	// ref course limit 
	const courseRef = doc(db, "_courses", "C:*");
	// const courseSnap = await getDoc(courseRef);
	// if(courseSnap.exists()) {
	// 	courseList = courseSnap.data().course_list.find((val:any) => val.course_code === course_code);
	// } else {

	// }

	// const limit = courseList.course_attend_limit;
	// return {status:true};

		
		
	// ref Course
	// get C:*:ATTD
	const courseATTDRef = doc(db, "_courses", "C:*:ATTD");
	/*
	let courseATTDSnap = await getDoc(courseATTDRef);
	if (!courseATTDSnap.exists()) {
		let parentPath = courseATTDRef.parent.path;
		let id = courseATTDRef.id;
		await setDoc(doc(collection(db, parentPath), id), {
			course_code_list: [],
			uid: userId,
		});
		courseATTDSnap = await getDoc(courseATTDRef);
	}
	courseATTD = courseATTDSnap.data() as courseATTDType;
	*/
	// get C:{course_code}:ATTD
	const courseCodeATTDRef = doc(db, "_courses", `C:${course_code}:ATTD`);
	/*
	let courseCodeATTDSnap = await getDoc(courseCodeATTDRef);
	if (!courseCodeATTDSnap.exists()) {
		let parentPath = courseATTDRef.parent.path;
		let id = courseATTDRef.id;
		await setDoc(doc(collection(db, parentPath), id), {
			course_code_list: [],
			uid: userId,
		});
		courseCodeATTDSnap = await getDoc(courseCodeATTDRef);
	}
	courseCodeATTD = courseCodeATTDSnap.data() as courseCodeATTDType;
	*/

	// get _users_courses, U:{userId}:COURSE
	const userCourseRef = doc(db, "_users_courses", `U:${userId}:COURSE`);
	/*
	let userCourseSnap = await getDoc(userCourseRef);
	if (!userCourseSnap.exists()) {
		let refParentPath = userCourseRef.parent.path;
		let refId = userCourseRef.id;
		await setDoc(doc(collection(db, refParentPath), refId), {
			course_code_list: [],
			uid: userId,
		});
		userCourseSnap = await getDoc(userCourseRef);
		// userCourse = userCourseSnap.data();
	}
	userCourse = userCourseSnap.data() as userCourseType;
	*/
	// console.log(courseATTD, courseCodeATTD, userCourse)
	// console.log(courseATTD, "courseATTD");

	// courseATTD

	// Initialize document
	// const cityRef = doc(collection(db, "cities"), "SF");
	// setDoc(cityRef,
	//   {
	//     name: 'San Francisco',
	//     state: 'CA',
	//     country: 'USA',
	//     capital: false,
	//     population: 860000
	//   }
	// )
	const userRef = doc(collection(db, "users"), userId);
	// get user for update credit
	// const userRef = doc(collection(db, "userTestx"), userId);
	// const userRef = doc(collection(db, "userTestx"), userId);
	/*
	let userSnap = await getDoc(userRef);
	userData = userSnap.data();
	*/
	// userData.student
	try {
		await runTransaction(db, async (t) => {
			// const doc = await t.get(cityRef);
			const courseSnap = await t.get(courseRef);
			if(courseSnap.exists()) {
				courseList = courseSnap.data().course_list.find((val:any) => val.course_code === course_code);
			} else {
		
			}
		
			const limit = parseInt(courseList.course_attend_limit) - 1;
			
			let courseATTDSnap = await t.get(courseATTDRef);
			if (!courseATTDSnap.exists()) {
				let parentPath = courseATTDRef.parent.path;
				let id = courseATTDRef.id;
				await t.set(courseATTDRef, {
					course_code_list: [],
					uid: userId,
				});
				courseATTDSnap = await t.get(courseATTDRef);
			}
			courseATTD = courseATTDSnap.data() as courseATTDType;

			let courseCodeATTDSnap = await t.get(courseCodeATTDRef);
			if (!courseCodeATTDSnap.exists()) {
				let parentPath = courseATTDRef.parent.path;
				let id = courseATTDRef.id;
				await t.set(courseCodeATTDRef, {
					course_code_list: [],
					uid: userId,
				});
				courseCodeATTDSnap = await t.get(courseCodeATTDRef);
			}
			courseCodeATTD = courseCodeATTDSnap.data() as courseCodeATTDType;

			let userCourseSnap = await t.get(userCourseRef);
			if (!userCourseSnap.exists()) {
				let refParentPath = userCourseRef.parent.path;
				let refId = userCourseRef.id;
				await t.set(userCourseRef, {
					course_code_list: [],
					uid: userId,
				});
				userCourseSnap = await t.get(userCourseRef);
				// userCourse = userCourseSnap.data();
			}
			userCourse = userCourseSnap.data() as userCourseType;

			let userSnap = await t.get(userRef);
			userData = userSnap.data();
			
			if(courseCodeATTD.attend_list.length > limit) {
				throw 'maxLimit'
			}
			
			// t.update(courseATTDRef, {course_list: courseATTD.course_list })
			// throw 'x';
			// Add one person to the city population.
			// Note: this could be done without a transaction
			//       by updating the population using FieldValue.increment()
			
			// const docData: any = doc.data();
			// const newPopulation = (docData.population += 1);
			// console.log(newPopulation);
			// t.update(cityRef, { population: newPopulation });
			// update COURSE ATTD
			if (courseATTD.course_list.length) {
				// ถ้ามี length จะเช็ค course_code ถ้าไม่มีจะ add ใหม่
				let course = courseATTD.course_list.find(
					(val) => val.course_code === course_code
				);
				if (course) {
					course.attend_list.push({ uid: userId });
					Object.assign(courseATTD.course_list, course);
				} else {
					courseATTD.course_list.push({
						attend_list: [{ uid: userId }],
						course_code: course_code,
					});
					// console.log(courseATTD);
				}
			} else {
				// ถ้าไม่มี length
				courseATTD = {
					course_list: [
						{
							attend_list: [{ uid: userId }],
							course_code: course_code,
						},
					],
				};
			}
			t.update(courseATTDRef, {course_list: courseATTD.course_list })

			// update c:{course_code}:attd
			courseCodeATTD.attend_list.push({ uid: userId });
			t.update(courseCodeATTDRef, {attend_list: courseCodeATTD.attend_list});

			// update userCourse
			userCourse.course_code_list.push(course_code);
			t.update(userCourseRef, {course_code_list: userCourse.course_code_list});

			// update user credit
			userData.student.credit -= 1;
			t.update(userRef, {student: userData.student});
			
			
		});
		console.log("Transaction success!");
		return {status:true};
	} catch (e) {
		console.log("Transaction failure:", e);
		return {status:false};
	}

	// await runTransaction(db, async (trans) => {
	//   const refDoc = await trans.get(docRef);
	//   console.log(refDoc);
	// })

	/*
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const snapshotData = docSnap.data();
    const { course_list } = snapshotData;
    let courseData = course_list.find((val:any) => val.course_code === course_code);
    if(courseData) {
      const {attend_list} = courseData;
      const arr = [...attend_list];
      arr.push({uid: userId});
      const data = [
        {
          attend_list : [...arr],
          course_code: course_code
        }
      ]
      console.log(docRef);
      const updateTimestamp = await updateDoc(docRef, {course_list:data});
    } else {
      console.log('undefinedxxx')
    }

    
    
  } else {
    console.log("can't find doc");

  }

  */
	// const courseRef = doc(db, "_courses", "C:*:ATTD");

	// console.log(courseRef);
	// const updateTimestamp = await updateDoc(courseRef, {
	//     timestamp: new Date()
	// });
	// console.log(updateTimestamp);
	// const docRef = await addDoc(collection(db, "_course"), {
	//   name: "Tokyo",
	//   country: "Japan"
	// });
	// console.log("Document written with ID: ", docRef.id);
};
/*
const checkBooking = async(course_code:string = "") => {
	// PHCH
	try {
		runTransaction(db, async (t) => {
			const courseRef = doc(db, "_courses", `C:*:ATTD`);
			const courseSnap:any = await t.get(courseRef);
			const course_list = courseSnap.data().course_list;
			const course = course_list.find((val:any) => {
				return val.course_code === course_code;
			});
			// console.log(course, 'course');

			const courseATTDRef = doc(db, "_courses", `C:${course_code}:ATTD`);
			const courseATTDSnap = await t.get(courseATTDRef);
			console.log(courseATTDSnap.data(), 'course_attd');
			
		})
	} catch(err) {
		console.log(err);
	}
}
*/
const updateUser = async(userStudent:userStudentType, userId:string) => {
	// const user
	const userRef = await doc(db, "users", userId);
	// const userSnap = await getDoc(userRef);
	// const userTestRef = await doc(db, "userTest", userId);
	// console.log(userSnap.data());
	try{
		await runTransaction(db, async(trans) => {

			trans.set(userRef, {
				student: {...userStudent}
			}, {merge:true})
		});
		return {status: true};
	} catch(err) {
		
		return {status: false};
	}
	// await setDoc((doc(db, "userTest", userId)), userSnap.data(), {merge: true})
	
	
}

const changeUserPasswordOnFireStore = async (uid:string, newPassword:string) => {
	const userRef = await doc(db, 'users', uid);
	getDoc(userRef).then(async (data) => {
		if(data.exists()) {
			try {
				await runTransaction(db, async(trans) => {
					trans.set(userRef, {
						student: {
							password: newPassword
						}
					}, {merge: true});
				})
			} catch(error) {
				console.log(error);
			}
		}
	});
	
}

type coursePost = {
	course_code:string, 
	course: courseType,
	post_video_url:string,
}

const getCoursePost = async () => {
	let postList:any = {};
	let courseList:any = {}
	const coursePostArray:any = [];
	const coursePostRef = await doc(db, '_courses', 'C:*:POST');
	const coursePostSnap = await getDoc(coursePostRef);
	const courseRef = await doc(db, '_courses', 'C:*');
	const courseSnap = await getDoc(courseRef);

	postList = Object.assign({}, coursePostSnap.data());
	
	courseList = Object.assign({}, courseSnap.data());
	
	
	// console.log(postList.course_list, 'postList.course_list');
	postList.course_list.map((val:coursePost, idx:number) => {
		// console.log(val, 'value')
		let obj = val;
		let course = courseList.course_list.find((v:courseType) => v.course_code === val.course_code);
	
		obj.course = course;
		coursePostArray.push(obj);
	})
	
	return coursePostArray;
}

/*
const cleanData = async() => {
	const courseAttdList:any[] = []; // update course attd
	const userCourseBook:any[] = []; // update all doc
	const users:any[] = []; // update all doc
	// clean course book
	const courseRef = doc(db, '_courses', 'C:*:ATTD');
	const courseSnap = await getDoc(courseRef);
	if(courseSnap.exists()){
		for(let item of courseSnap.data().course_list) {
			item.attend_list = [];
			courseAttdList.push(item);
		}
	}
	
	// clean user course 
	const qUC = query(collection(db, "_users_courses"));
	const querySnapshot = await getDocs(qUC);
	querySnapshot.forEach((doc) => {
	  // doc.data() is never undefined for query doc snapshots
	  let data = Object.assign({}, doc.data());
	  data.course_code_list = [];
	  let obj = {
		id: doc.id,
		data:data,
	  }
	  userCourseBook.push(obj);
	});


	// clean user course 
	const qUser = query(collection(db, "users"));
	const queryUserSnapshot = await getDocs(qUser);
	queryUserSnapshot.forEach((doc) => {
	  // doc.data() is never undefined for query doc snapshots
	  let data = Object.assign({}, doc.data());
	//   data.course_code_list = [];
	if(data.student) {
		data.student.credit = 4
	}
	
	  let obj = {
		id: doc.id,
		data:data,
	  }

	  users.push(obj);
	
	});

	try { 
		runTransaction(db, async(trans) => {
			// update course
			await trans.update(courseRef, {
				course_list: courseAttdList
			})

			// update course
			for(let item of userCourseBook) {
				const userCourseRef = await doc(db, '_users_courses', item.id);
				await trans.update(userCourseRef, item.data);
			}
			// update user credit
			for(let item of users) {
				const userRef = await doc(db, 'users', item.id);
				await trans.update(userRef, item.data);
			}
		})

		console.log('success')
	} catch(err) { 
		console.log(err, 'catch');
	}
}

// const addDataTestLoop = async() => {
// 	try {
// 		runTransaction(db, async(trans) => {
// 			for(let i = 0; i < 200; i++) {
// 				const docRef = doc(db, 'users', `user${i}`);
// 				await trans.set(docRef, {
// 					authProvider:'local',
// 					email:`ada${i}@test.com`,
// 					name:`Ada${i} Wong`,
// 					student: {
// 						credit:4,
// 						email:`ada${i}@test.com`,
// 						branch: 'b1',
// 						firstName: 'Ada',
// 						lastName: 'Wong',
// 						phone:'0816488111',
// 						role: true,
// 						school:'PKRU',
// 						type:'student',
// 						year:'1'
// 					},
// 					uid:`Ada${i}`
// 				})
// 			}
//
// 		})
// 		console.log('success')
// 	} catch(err) {
// 		console.log(err, 'catch');
// 	}
// }

const bookingCourseListLoop = async() => {
	// const arr =[];
	// for(let i = 0; i< 10;i++) {
	// 	arr.push(r(i));
	// }
	
	// const resultPromise = await Promise.all(arr);
	// console.log(resultPromise);
	// return false;
	try {
		runTransaction(db, async(trans) => {
			const userArray:any = [];
			const promiseArr = [];
			const qUser = query(collection(db, "users"));
			const userSnap = await getDocs(qUser);
			let count = 0;
			userSnap.forEach((doc) => {
				let obj = {
					id: doc.id,
					data:doc.data(),
				}
				// if(count < 200) {
				userArray.push(obj);
				// };
				// count++;
				// console.log(doc.id, '=>', doc.data());
			})
			let user = userArray.sort((a:any, b:any) => a.id - b.id);
			console.log(user.length);
			// console.log(user);
			for(let item of user) {
				console.log(item.id);
				let book = bookingCourseTest(item.id, "L001");
				promiseArr.push(book);
			}	
			// console.log(promiseArr);
			const result = Promise.all(promiseArr);
			console.log(result);
			
		})
		console.log('transaction success');
	} catch(err) { 
		console.log(err, 'catch');
	}
	
}
*/
export {
	getAllCourse,
	getAllCourseATTD,
	getCourseById,
	bookingCourse,
	getUserCourse,
	getUser,
	updateUser,
	changeUserPasswordOnFireStore,
	getCoursePost
	// cleanData,
	// addDataTestLoop,
	// bookingCourseListLoop,
	// checkBooking,
};
