import {extendTheme} from '@chakra-ui/react';
// import {theme} from '@chakra-ui/pro-theme';
import {theme} from './theme';

const AppTheme = extendTheme(
    {
        colors: {...theme.colors, brand: theme.colors.orange},
    },
    theme,
);

export default AppTheme;
