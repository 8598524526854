import {
    Center,
    Box,
    Text,
    Flex
} from '@chakra-ui/react';


const Footer = (props:any) => {
    return (
        <Box py='5' px='5' bgColor='white'>
            <Flex direction={{base: 'column', md:"row"}} align='center' justify="space-between">
                <Text fontSize='md' color='black'>&copy; {new Date().getFullYear()} Develop by Uniclass.</Text>
                <Text fontSize='md' color='black'>App Version: {process.env.REACT_APP_VERSION}</Text>
            </Flex>
        </Box>
    )
}

export default Footer;