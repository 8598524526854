import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import {FaAdjust, FaSchool, FaStar} from 'react-icons/fa';
import {RiNumber1, RiNumber2, RiNumber3, RiNumber4, RiNumber5} from 'react-icons/ri';
import 'react-vertical-timeline-component/style.min.css';

const Timeline = (props: any) => {
    return (
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"

                // contentStyle={{ background: 'rgba(254,198,39,255)', color: '#fff' }}
                // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                // date="2011 - present"
                // iconStyle={{ background: 'rgba(254,198,39,255)', color: '#fff' }}
                // contentArrowStyle={{borderRight: '7px solid  rgb(33, 150, 243)'}}
                // contentStyle={{borderColor: 'red', colorScheme: 'red'}}
                iconStyle={{background: 'orange', color: '#fff'}}
                icon={<RiNumber1/>
                }
            >
                <div className='text-center font-bold pb-3'>เริ่มลงทะเบียนกันเลย</div>
                <div className="text-left">
                    ลงทะเบียนง่ายๆโดยใส่email หรือชื่อผู้ใช้และรหัสผ่านเท่านั้น
                    สำหรับผู้ที่ยังไม่ได้สมัครสมาชิกสามารถเข้าสมัครสมาชิกได้โดยไม่เสียค่าธรรมเนียมใดๆ
                </div>

            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2010 - 2011"
                iconStyle={{background: 'orange', color: '#fff'}}
                icon={<RiNumber2 colorProfile={'red'}/>
                }
            >
                <div className='text-center font-bold pb-3'>ทำแบบทดสอบวัดระดับภาษาอังกฤษ</div>
                <div className='text-left'>
                    ผลการทดสอบวัดระดับภาษาอังกฤษจะช่วยยืนยันได้ว่าเรามีความสามารถในการใช้ภาษาอังกฤษระดับไหนและควรเพิ่มเติมในทักษะด้านใด
                    และเพื่อเป้าหมายการเรียนภาษาอังกฤษของน้องๆ
                </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2008 - 2010"
                iconStyle={{background: 'orange', color: '#fff'}}
                icon={<RiNumber3/>
                }
            >
                <div className='text-center font-bold pb-3'>จองคอร์สเรียนสด จำนวน 4 ครั้ง</div>
                <div className='text-left'>
                    เรียนภาษาอังกฤษจากไม่มีพื้นฐาน การฟัง การพูด การอ่าน และการเขียน ทั้ง 4 ทักษะ
                </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // date="2006 - 2008"
                iconStyle={{background: 'orange', color: '#fff'}}
                icon={<RiNumber4/>
                }
            >
                <div className='text-center font-bold pb-3'>เริ่มเรียนผ่านโปรแกรม Zoom ตามวันเวลาที่จองไว้</div>
                <div className='text-left'>
                    เรียนสด ถามตอบได้ทันที กลุ่มเล็กไม่เกินกลุ่มละ 10 คน สามารถพูดคุยผ่านวิดีโอ
                    ส่งภาพและเสียงระหว่างการเรียนการสอน
                </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                // date="April 2013"
                iconStyle={{background: 'orange', color: '#fff'}}
                icon={<RiNumber5/>
                }
            >
                <div className='text-center font-bold px-3 py-3 text-2xl'>ดูคอร์สเรียนย้อนหลังไม่อั้น</div>
                <div className='text-left'>
                    สําหรับรายวิชาที่มีการเรียนการสอนสอนผ่านคอร์สออนไลน์จะมีการบันทึกไว้เพื่อดูย้อนหลัง
                    ระบบจะแสดงวิดีโอที่บันทึกไว้ นักเรียนสามารถเลือกดูวีดีโอย้อนหลังได้
                </div>
            </VerticalTimelineElement>

        </VerticalTimeline>
    )
}

export default Timeline;
