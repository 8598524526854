import React, {useState, useEffect} from 'react';
import {
    Flex,
    GridItem,
    Box,
    Text,
    Stack,
    Heading,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    useDisclosure,
    Button,
    Center, Divider, Icon, HStack, OrderedList, ListItem, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Badge
} from '@chakra-ui/react';
import {bookingCourse as userBookingCourse} from '../../../../firestore/collection';
import AlertDialogCustom from '../AlertDialogCustom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import {JsxElement} from 'typescript';
import {useSWRConfig} from 'swr';
import {RiGroupFill} from 'react-icons/ri';
import {BiCalendar} from 'react-icons/bi';
import {FiClock} from 'react-icons/fi';
import {BsCardHeading, BsCheckCircle, BsFillPersonFill} from 'react-icons/bs';

const shortMonth = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];

function showFormateTimeRange(val: any) {
    const dateStart = new Date(val.datetime_start*1000);
    const dateEnd = new Date(val.datetime_end*1000);
    // console.log(val.datetime_start, 'start',  val.datetime_end, 'end')
    const month = shortMonth[dateStart.getMonth()];
    const hStart = ('0' + dateStart.getHours()).slice(-2);
    const mStart = ('0' + dateStart.getMinutes()).slice(-2);
    const hEnd = ('0' + dateEnd.getHours()).slice(-2);
    const mEnd = ('0' + dateEnd.getMinutes()).slice(-2);
    const time24Start = `${hStart}:${mStart}`
    const time24End = `${hEnd}:${mEnd}`
    return `${dateStart.getDate()} ${month} ${time24Start} - ${time24End}`;
}

const CourseItem = (props: any) => {

    const [courseFocus, setCourseFocus] = useState<string>("");
    const [bookingLoading, setBookingLoading] = useState<boolean>(false);
    const alertDisclosure = useDisclosure();
    const cancelRef = React.useRef(null);
    const MySwal = withReactContent(Swal);
    const [active, setActive] = useState<boolean>(true);
    // const [active, setActive] = useState<boolean>(false);
    const [booked, setBooked] = useState<boolean>(false);
    const [credit, setCredit] = useState<number | undefined>();

    // console.log(props);

    useEffect(() => {
        const {course_code, course_attend_limit} = props && props.course;
        // console.log(props);
        const userCourse = props.userCourse && props.userCourse;
        // console.log(userCourse);
        const attd = props.course_list_attd && props.course_list_attd.find((val: any) => val.course_code === course_code)
        // console.log(props);
        if (userCourse) {
            if (userCourse.course_code_list.includes(course_code)) {
                setBooked(true);
                // console.log(course_code);
            }
        }
        if (attd.attend_list.length === course_attend_limit) setActive(false);
        // console.log(course_code, course_attend_limit)
    }, [active, props.course_list_attd, props])


    useEffect(() => {
        if (typeof (props.userCredit) != undefined) {
            setCredit(props.userCredit);
        }
    }, [props.userCredit])

    const openAlertDialog = (courseCode: string) => {
        // console.log(booked);

        const {course_code_list} = props.userCourse && props.userCourse;
        if (!course_code_list) props.callMutateAll();
        setCourseFocus(courseCode);
        if (!course_code_list.includes(courseCode)) {
            alertDisclosure.onOpen();
        }
    }

    const bookingCourse = async () => {

        setBookingLoading(true);
        const t = await userBookingCourse(props.userId, courseFocus);
        if (t.status) {
            setBookingLoading(false);
            alertDisclosure.onClose();
            props.callMutateAll();
            MySwal.fire({
                title: <strong>จองคอร์สสำเร็จแล้ว !</strong>,
                icon: 'success'
            })
        } else {
            setBookingLoading(false);
            MySwal.fire({
                title: <strong>จองคอร์สไม่สำเร็จ !</strong>,
                html: <i>กรุณาติดต่อแอดมิน</i>,
                icon: 'error'
            })
        }
    }

    const AlertButtonContent = (props: any) => {
        return (
            <Stack spacing='3' direction={{base: 'row'}} align='center' justify='center'>
                <Button w='100px' ref={cancelRef} onClick={alertDisclosure.onClose} colorScheme="red"
                        isDisabled={bookingLoading}>ยกเลิก</Button>
                <Button w='100px' colorScheme={'green'} onClick={bookingCourse} isLoading={bookingLoading}>ใช่</Button>
            </Stack>
        )
    }

    const AlertBodyContent = (props: any) => {
        const {course_name} = props;
        return (
            <Stack direction={{base: "row"}}>
                <Text>คุณต้องการจองคอร์ส </Text>
                <Text fontWeight={'bold'}>{course_name}</Text>
                <Text>หรือไม่?</Text>
            </Stack>
        )
    }

    const showCourseAttend = (course_attend_limit: string = "", course_attd: any) => {
        var textResult = '';
        textResult = `${course_attd.attend_list.length} / ${course_attend_limit} `
        // if(course_attd.attend_list.length === course_attend_limit) setActive(false)
        return textResult;
    }

    const color = (active: boolean) => {
        return active ? 'black' : 'black';
    }

    const gridItemClassName = (active: boolean) => {
        // return active ? 'cursor-pointer bg-lime-400' : 'cursor-pointer bg-red-500';
        active = true;
        return active ? 'transition hover:transition cursor-pointer bg-white hover:border-2 hover:border-blue-400 hover:bg-gray-100' :
            'transition hover:transition cursor-pointer bg-red-500 hover:border-2 hover:border-red-500';
        // return  'cursor-pointer bg-black';
    }

    return (
        <Popover trigger='hover' placement='right'>
            <PopoverTrigger>
                <GridItem
                    w='100%'
                    h='auto'
                    boxShadow={'xl'}
                    borderWidth={2}
                    borderColor={'white'}
                    borderRadius={7}
                    className={gridItemClassName(active)}
                    onClick={() => openAlertDialog(props.course.course_code)}
                    position={'relative'}
                >
                    {
                        booked &&
                        <Box
                            position={'absolute'}
                            left='0'
                            right='0'
                            top='0'
                            bottom='0'
                            bgColor='blackAlpha.500'
                            borderRadius={7}
                        >
                            <Flex w='100%' h='100%' align={'center'} justify='center'>
                                <Heading as='h3' size={'xl'} color='white'>
                                    <Icon as={BsCheckCircle}/>
                                    &nbsp;จองแล้ว</Heading>
                            </Flex>
                        </Box>
                    }
                    {

                        (!booked && !active) &&
                        <Box
                            position={'absolute'}
                            left='0'
                            right='0'
                            top='0'
                            bottom='0'
                            bgColor='blackAlpha.500'
                            borderRadius={7}
                        >
                            <Flex w='100%' h='100%' align={'center'} justify='center'>
                                <Heading as='h3' size={'xl'} color='white'>เต็มแล้ว</Heading>
                            </Flex>
                        </Box>
                    }
                    <Box p='5'>
                        <Stack spacing='3'>
                            <Flex flexDirection={{base: 'row'}} align={'flex-start'} justify={'space-between'}>
                                <Box>
                                    <Heading color={color(active)} as='h3'
                                             size='md'>{props.course.course_name}
                                        <HStack>
                                            <Text fontSize={'xs'}>รหัส: </Text>
                                            <Text fontSize={'xs'}>{props.course.course_code}</Text>
                                        </HStack>
                                    </Heading>
                                </Box>
                                <Box>
                                    <HStack>
                                        <Icon as={RiGroupFill}/>
                                        <Text>{showCourseAttend(props.course.course_attend_limit, props.course_list_attd.find((val: any) => val.course_code === props.course.course_code))}</Text>
                                    </HStack>
                                </Box>
                            </Flex>
                            <Divider/>
                            {/*<HStack>*/}
                            {/*    <Text fontSize={'xs'}>รหัส: </Text>*/}
                            {/*    <Text fontSize={'xs'}>{props.course.course_code}</Text>*/}
                            {/*</HStack>*/}
                            <HStack>
                                <Icon as={FiClock}/>
                                <Badge ml='1' variant='outline'>
                                    <Text fontSize={'md'}
                                          color={color(active)}>{showFormateTimeRange(props.course.course_airtime_list[0])}</Text>
                                </Badge>
                            </HStack>

                            <HStack>
                                <Icon as={BsFillPersonFill} />
                                <Text fontSize={'md'}
                                        color={color(active)}>                    {
                                        props.course.course_teacher_list && 
                                        props.course.course_teacher_list.map((teacher:any, idx:number) => {
                                            return <Text key={idx}>{teacher.teacher_name}</Text>
                                        })
                                    }</Text>
                            </HStack>
                            <Stack w='250px' noOfLines={2} minH='50px'>
                                <Text color={color(active)} noOfLines={3}>{props.course.course_desc}</Text>
                            </Stack>
                            {/*<Text color={color(active)}>เรียน: <b>4</b> ครั้ง</Text>*/}
                            {/*<Text color={color(active)}>เวลาเรียน: {showFormateTimeRange(props.course.course_airtime_list[0])}</Text>*/}
                        </Stack>
                        <AlertDialogCustom
                            onOpen={alertDisclosure.onOpen}
                            isOpen={alertDisclosure.isOpen}
                            onClose={alertDisclosure.onClose}
                            dialogHeaderContent={(credit && credit > 0) ? active ? "ต้องการจองคอร์สเรียน ?" : "คอร์สเรียนเต็มแล้ว" : "เครดิตการจองหมดแล้ว"}
                            dialogBodyContent={(credit && credit > 0) ? active ?
                                <AlertBodyContent course_name={props.course.course_name} active={active}/> : "" : ""}
                            dialogButtonContent={(credit && credit > 0) ? active ?
                                <AlertButtonContent active={active}/> : "" : ""}
                        />
                    </Box>
                </GridItem>
            </PopoverTrigger>
            {/*<PopoverContent>*/}
            {/*    /!* <PopoverArrow />*/}
            {/*    <PopoverCloseButton />*/}
            {/*    <PopoverHeader>Confirmation!</PopoverHeader> *!/*/}
            {/*    <PopoverArrow/>*/}
            {/*    <PopoverBody>*/}
            {/*        <Stack m={2}>*/}
            {/*            <HStack><Icon as={BiCalendar}/><Text>ตารางเรียน</Text></HStack>*/}
            {/*            <Divider/>*/}
            {/*            /!*<Stack px='4'>*!/*/}
            {/*            /!*    {props.course.course_airtime_list.map((val: any, idx: string) => {*!/*/}
            {/*            /!*        const dateStart = new Date(val.datetime_start);*!/*/}
            {/*            /!*        const dateEnd = new Date(val.datetime_end);*!/*/}
            {/*            /!*        // console.log(val.datetime_start, 'start',  val.datetime_end, 'end')*!/*/}
            {/*            /!*        const month = shortMonth[dateStart.getMonth()];*!/*/}
            {/*            /!*        const hStart = ('0' + dateStart.getHours()).slice(-2);*!/*/}
            {/*            /!*        const mStart = ('0' + dateStart.getMinutes()).slice(-2);*!/*/}
            {/*            /!*        const hEnd = ('0' + dateEnd.getHours()).slice(-2);*!/*/}
            {/*            /!*        const mEnd = ('0' + dateEnd.getMinutes()).slice(-2);*!/*/}
            {/*            /!*        const time24Start = `${hStart}:${mStart}`*!/*/}
            {/*            /!*        const time24End = `${hEnd}:${mEnd}`*!/*/}
            {/*            /!*        const result = `${dateStart.getDate()} ${month} ${time24Start} - ${time24End}`;*!/*/}
            {/*            /!*        return <Text key={idx}>{result}{val.airtime_header}</Text>*!/*/}
            {/*            /!*    })}*!/*/}
            {/*            /!*</Stack>*!/*/}
            {/*            <OrderedList px='4'>*/}
            {/*                {*/}
            {/*                    props.course.course_airtime_list.map((val: any, idx: number) => {*/}
            {/*                        const dateStart = new Date(val.datetime_start*1000);*/}
            {/*                        const dateEnd = new Date(val.datetime_end*1000);*/}
            {/*                        // console.log(val.datetime_start, 'start',  val.datetime_end, 'end')*/}
            {/*                        const month = shortMonth[dateStart.getMonth()];*/}
            {/*                        const hStart = ('0' + dateStart.getHours()).slice(-2);*/}
            {/*                        const mStart = ('0' + dateStart.getMinutes()).slice(-2);*/}
            {/*                        const hEnd = ('0' + dateEnd.getHours()).slice(-2);*/}
            {/*                        const mEnd = ('0' + dateEnd.getMinutes()).slice(-2);*/}
            {/*                        const time24Start = `${hStart}:${mStart}`*/}
            {/*                        const time24End = `${hEnd}:${mEnd}`*/}
            {/*                        const result = `${dateStart.getDate()} ${month} ${time24Start} - ${time24End}`;*/}
            {/*                        // return <ListItem key={idx}>*/}
            {/*                        //     <Stack>*/}
            {/*                        //         <Text fontSize={'md'}>{val.airtime_header}</Text>*/}
            {/*                        //         <HStack>*/}
            {/*                        //             <Icon as={FiClock}/>*/}
            {/*                        //             <Text fontSize={'md'}>{result}</Text>*/}
            {/*                        //         </HStack>*/}
            {/*                        //     </Stack>*/}
            {/*                        // </ListItem>*/}
            {/*                        // return <ListItem key={idx}>{result}</ListItem>*/}
            {/*                        return <ListItem key={idx} pl={2}>*/}
            {/*                            <Stack pt={2}>*/}
            {/*                                <HStack>*/}
            {/*                                    <Icon as={BsCardHeading}/>*/}
            {/*                                    <Text fontSize={'xs'}>{val.airtime_header}</Text>*/}
            {/*                                </HStack>*/}
            {/*                                <HStack>*/}
            {/*                                    <Icon as={FiClock}/>*/}
            {/*                                    <Text fontSize={'xs'}>{result}</Text>*/}
            {/*                                </HStack>*/}
            {/*                                {idx !== props.course.course_airtime_list.length - 1 && <Divider/>}*/}
            {/*                            </Stack>*/}
            {/*                        </ListItem>*/}
            {/*                    })*/}
            {/*                }*/}
            {/*            </OrderedList>*/}

            {/*        </Stack>*/}

            {/*    </PopoverBody>*/}
            {/*</PopoverContent>*/}
        </Popover>

    )
}


export default CourseItem;
