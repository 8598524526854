import React, {useState, useEffect} from "react";
import {
    Input,
    Button,
    Stack,
    Box,
    Flex,
    Center,
    Heading,
    Text,
} from "@chakra-ui/react";
import CourseList from "../course/CourseList";
import { courseType } from "../../../../firestore";

// import { useSWRConfig } from 'swr';
// const courseSkillType = [
//   { courseType: "L", courseTypeText: "ฟัง", active:true },
//   { courseType: "S", courseTypeText: "พูด", active:true  },
//   { courseType: "R", courseTypeText: "อ่าน", active:true  },
//   { courseType: "W", courseTypeText: "เขียน", active:true  },
// ];
const Monitor = (props: any) => {
    
    const [courseType, setCourseType] = useState([
        { courseType: "L", courseTypeText: "ฟัง", active:true },
        { courseType: "S", courseTypeText: "พูด", active:true  },
        { courseType: "R", courseTypeText: "อ่าน", active:true  },
        { courseType: "W", courseTypeText: "เขียน", active:true  },
    ]);

    const changeActive = (cType: string, active: boolean) => {
        setCourseType([...courseType].map(object => {
            if(object.courseType === cType)  {
                object.active = !active;
                return {
                    ...object
                };
            } else {
                return object;
            } 

        }))
    }

    const inputNumber = (elem:any) => {
        let { value:val } = elem.target;
        const formatNumber = new RegExp('[0-9]', 'g')
        // console.log(val);
        // console.log(formatNumber.test(val));
        if(formatNumber.test(val)) {
            
            elem.target.value = val;
        }
        // console.log(formatNumber.test(val));
    }

    // const isNumberKey = (event:any) => {
    //     const result = event.target.value.replace(/\D/g, '');
    //     console.log(result);
    // }

    return (
        <Box>
            <Box p={{base:3, md:"7"}}>
                <Stack spacing="5">
                <Flex align="center" justify="space-between" direction={{base:'column', md:'row'}}>
                    <Text className="font-bold" fontSize={'lg'}>เครดิตการจอง {props.userData && props.userData.credit} ครั้ง</Text>
                    {/* <Text>Tree</Text> */}
                    <Flex flexDirection={{base:'row'}} align='center' justify='center' gap={0.5}>
                        {
                            courseType.map((val, idx) => {
                                return (
                                    <Box key={idx}>
                                        <Button borderRadius={0} colorScheme={val.active ? 'twitter' : 'gray'} onClick={() => changeActive(val.courseType, val.active)}>
                                            <Center>
                                                <Text color={val.active ? 'white' : 'black'}>{val.courseTypeText}</Text>
                                            </Center>
                                        </Button>
                                    </Box>
                                )
                            })
                        }
                    </Flex>
                </Flex>
                <Stack px={{base:0, md:"4"}} spacing="3">
                    {courseType.map((val, index) => {
                    const { courseType, courseTypeText, active } = val;
                    return (
                        active && (
                        <Stack key={index} pt={6}>
                            <Heading as='h1' size='xl'>{courseTypeText}</Heading>
                            {
                            props.course_list && (
                            <CourseList
                                courseList={props.course_list.filter(
                                (value: courseType) => value.course_skill_type === courseType
                                )}
                                userId={props.userId && props.userId}
                                userCourse={props.userCourse && props.userCourse}
                                course_list_attd={props.course_list_attd && props.course_list_attd}
                                userCredit={props.userData && props.userData.credit}
                                callMutateAll={props.mutateAll}
                            />
                            )
                            }
                        </Stack>
                        )
                    );
                    })}
                </Stack>
                </Stack>
            </Box>
        </Box>
    );
};

export default Monitor;
