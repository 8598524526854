import { createIcon } from "@chakra-ui/react";

export const ZoomIcon = (props:any) => {
    const w = props.w ? props.w : 32;
    const h = props.h ? props.h : 32;
    return <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} fill="none" viewBox="0 0 32 32">
        <path
            d="M2 11.6C2 8.23969 2 6.55953 2.65396 5.27606C3.2292 4.14708 4.14708 3.2292 5.27606 2.65396C6.55953 2 8.23969 2 11.6 2H20.4C23.7603 2 25.4405 2 26.7239 2.65396C27.8529 3.2292 28.7708 4.14708 29.346 5.27606C30 6.55953 30 8.23969 30 11.6V20.4C30 23.7603 30 25.4405 29.346 26.7239C28.7708 27.8529 27.8529 28.7708 26.7239 29.346C25.4405 30 23.7603 30 20.4 30H11.6C8.23969 30 6.55953 30 5.27606 29.346C4.14708 28.7708 3.2292 27.8529 2.65396 26.7239C2 25.4405 2 23.7603 2 20.4V11.6Z"
            fill="#4087FC"
        />
        <path
            d="M8.26667 10C7.56711 10 7 10.6396 7 11.4286V18.3571C7 20.369 8.44612 22 10.23 22L17.7333 21.9286C18.4329 21.9286 19 21.289 19 20.5V13.5C19 11.4881 17.2839 10 15.5 10L8.26667 10Z"
            fill="white"
        />
        <path
            d="M20.7122 12.7276C20.2596 13.1752 20 13.8211 20 14.5V17.3993C20 18.0782 20.2596 18.7242 20.7122 19.1717L23.5288 21.6525C24.1019 22.2191 25 21.7601 25 20.9005V11.1352C25 10.2755 24.1019 9.81654 23.5288 10.3832L20.7122 12.7276Z"
            fill="white"
        />
    </svg>
}

export const GoogleMeetIcon = (props:any) => {
  const w = props.w ? props.w : 32;
  const h = props.h ? props.h : 32;
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={w} height={h} viewBox="0 0 87.5 72">
      <path fill="#00832d" d="M49.5 36l8.53 9.75 11.47 7.33 2-17.02-2-16.64-11.69 6.44z"/>
      <path fill="#0066da" d="M0 51.5V66c0 3.315 2.685 6 6 6h14.5l3-10.96-3-9.54-9.95-3z"/>
      <path fill="#e94235" d="M20.5 0L0 20.5l10.55 3 9.95-3 2.95-9.41z"/>
      <path fill="#2684fc" d="M20.5 20.5H0v31h20.5z"/>
      <path fill="#00ac47" d="M82.6 8.68L69.5 19.42v33.66l13.16 10.79c1.97 1.54 4.85.135 4.85-2.37V11c0-2.535-2.945-3.925-4.91-2.32zM49.5 36v15.5h-29V72h43c3.315 0 6-2.685 6-6V53.08z"/>
      <path fill="#ffba00" d="M63.5 0h-43v20.5h29V36l20-16.57V6c0-3.315-2.685-6-6-6z"/>
    </svg>
}

export const SkypeIcon = (props:any) => {
  const w = props.w ? props.w : 32;
  const h = props.h ? props.h : 32;
  return <svg xmlns="http://www.w3.org/2000/svg" height={h} width={w}>
  <defs>
    <linearGradient
      id="a"
      x1="8.392999649"
      y1="1.105999947"
      x2="11.125"
      y2="18.35400009"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset=".012" stop-color="#00b7f0" />
      <stop offset=".339" stop-color="#009de5" />
      <stop offset=".755" stop-color="#0082d9" />
      <stop offset="1" stop-color="#0078d4" />
    </linearGradient>
    <linearGradient
      id="b"
      x1="14.37899971"
      y1="26.64500046"
      x2="29.54599953"
      y2="17.88899994"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#0078d4" />
      <stop offset=".37" stop-color="#007ad5" />
      <stop offset=".573" stop-color="#0082d9" />
      <stop offset=".735" stop-color="#0090df" />
      <stop offset=".875" stop-color="#00a3e7" />
      <stop offset="1" stop-color="#00bcf2" />
    </linearGradient>
    <linearGradient
      id="c"
      x1="10.44999981"
      y1="6.68900013"
      x2="24.29100037"
      y2="30.66200066"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#00b7f0" />
      <stop offset="1" stop-color="#007cc1" />
    </linearGradient>
  </defs>
  <g transform="translate(-381.7262001,-337.1428572)">
    <path
      d="m 18.51799965,9.729999542 a 8.758999825,8.729999542 0 0 1 -8.758999825,8.729999538 8.758999825,8.729999542 0 0 1 -8.758999825,-8.729999538 8.758999825,8.729999542 0 0 1 8.758999825,-8.729999542 8.758999825,8.729999542 0 0 1 8.758999825,8.729999542"
      transform="matrix(33.33333333,0,0,33.33333333,348.3928668,303.8095239)"
      
    />
    <path
      d="m 30.72499943,22.2670002 a 8.762000084,8.732999802 0 0 1 -8.76200009,8.7329998 8.762000084,8.732999802 0 0 1 -8.76200008,-8.7329998 8.762000084,8.732999802 0 0 1 8.76200008,-8.7329998 8.762000084,8.732999802 0 0 1 8.76200009,8.7329998"
      transform="matrix(33.33333333,0,0,33.33333333,348.3928668,303.8095239)"
      
    />
    <path
      d="m 1.032,10.477 a 8.758,8.758 0 1 0 9.817,-9.41 15.915,15.915 0 0 0 -9.817,9.41 z"
      fill-rule="evenodd"
      opacity=".05"
      transform="matrix(33.33333333,0,0,33.33333333,348.3928668,303.8095239)"
    />
    <path
      d="m 30.702,21.64 a 8.761,8.761 0 1 0 -9.533,9.324 15.915,15.915 0 0 0 9.533,-9.325 z"
      fill-rule="evenodd"
      opacity=".05"
      transform="matrix(33.33333333,0,0,33.33333333,348.3928668,303.8095239)"
    />
    <path
      d="m 1.442,12.473 a 8.755,8.755 0 1 0 11.398,-10.917 14.893,14.893 0 0 0 -11.398,10.917 z"
      fill-rule="evenodd"
      opacity=".1"
      transform="matrix(33.33333333,0,0,33.33333333,348.3928668,303.8095239)"
    />
    <path
      d="m 30.304,19.588 a 8.758,8.758 0 1 0 -11.192,10.939 14.893,14.893 0 0 0 11.192,-10.939 z"
      fill-rule="evenodd"
      opacity=".1"
      transform="matrix(33.33333333,0,0,33.33333333,348.3928668,303.8095239)"
    />
    <path
      d="m 1.764,13.301 a 8.753,8.753 0 1 0 11.894,-11.39 14.387,14.387 0 0 0 -11.894,11.39 z"
      fill-rule="evenodd"
      opacity=".1"
      transform="matrix(33.33333333,0,0,33.33333333,348.3928668,303.8095239)"
    />
    <path
      d="m 29.981,18.743 a 8.756,8.756 0 1 0 -11.713,11.444 14.386,14.386 0 0 0 11.713,-11.444 z"
      fill-rule="evenodd"
      opacity=".1"
      transform="matrix(33.33333333,0,0,33.33333333,348.3928668,303.8095239)"
    />
    <path
      d="m 29.73700047,16.06500053 a 13.87300014,13.82699966 0 0 1 -13.87300015,13.82699967 13.87300014,13.82699966 0 0 1 -13.873000145,-13.82699967 13.87300014,13.82699966 0 0 1 13.873000145,-13.82699966 13.87300014,13.82699966 0 0 1 13.87300015,13.82699966"
      transform="matrix(33.33333333,0,0,33.33333333,348.3928668,303.8095239)"
      
    />
    <path
      d="m 11.963,15.585 a 4.358,4.358 0 0 1 -1.367,-1.442 3.986,3.986 0 0 1 -0.468,-1.993 3.582,3.582 0 0 1 0.844,-2.412 5.08,5.08 0 0 1 2.2,-1.47 8.495,8.495 0 0 1 2.88,-0.485 11.184,11.184 0 0 1 1.916,0.147 6.918,6.918 0 0 1 1.329,0.343 2.576,2.576 0 0 1 1.04,0.664 1.344,1.344 0 0 1 0.321,0.893 1.294,1.294 0 0 1 -0.338,0.931 1.124,1.124 0 0 1 -0.849,0.354 1.529,1.529 0 0 1 -0.631,-0.142 11.434,11.434 0 0 0 -1.503,-0.55 5.455,5.455 0 0 0 -1.448,-0.18 3.305,3.305 0 0 0 -1.814,0.464 1.509,1.509 0 0 0 -0.724,1.355 1.363,1.363 0 0 0 0.376,0.953 3.426,3.426 0 0 0 1.013,0.725 q 0.637,0.316 1.9,0.838 a 1.97,1.97 0 0 1 0.272,0.109 13.497,13.497 0 0 1 2.352,1.209 4.742,4.742 0 0 1 1.448,1.486 3.92,3.92 0 0 1 0.523,2.064 4.151,4.151 0 0 1 -0.724,2.488 4.304,4.304 0 0 1 -2.025,1.503 8.537,8.537 0 0 1 -3.044,0.496 9.59,9.59 0 0 1 -4.149,-0.785 2.258,2.258 0 0 1 -0.854,-0.664 1.493,1.493 0 0 1 -0.278,-0.904 1.181,1.181 0 0 1 0.365,-0.92 1.345,1.345 0 0 1 0.941,-0.332 2.407,2.407 0 0 1 1.1,0.283 q 0.632,0.316 0.997,0.474 a 5.008,5.008 0 0 0 0.86,0.272 4.92,4.92 0 0 0 1.105,0.114 2.95,2.95 0 0 0 1.857,-0.495 1.635,1.635 0 0 0 0.626,-1.356 1.44,1.44 0 0 0 -0.392,-0.99 4.167,4.167 0 0 0 -1.154,-0.84 q -0.763,-0.402 -2.146,-0.98 a 13.291,13.291 0 0 1 -2.357,-1.225"
      fill="#fff"
      fill-rule="evenodd"
      transform="matrix(33.33333333,0,0,33.33333333,348.3928668,303.8095239)"
    />
  </g>
</svg>

}

export const GoogleIcon = createIcon({
  displayName: "GoogleIcon",
  path: (
    <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
      <path
        fill="#4285F4"
        d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
      />
      <path
        fill="#34A853"
        d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
      />
      <path
        fill="#FBBC05"
        d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
      />
      <path
        fill="#EA4335"
        d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
      />
    </g>
  ),
});

export const GitHubIcon = createIcon({
  displayName: "GitHubIcon",
  path: (
    <path
      fill="currentColor"
      d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
    />
  ),
});

export const TwitterIcon = createIcon({
  displayName: "TwitterIcon",
  path: (
    <path
      fill="#03A9F4"
      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
    />
  ),
});
