import React, { useEffect, useState } from "react";
import LayoutUser from "../LayoutUser";
import Monitor from "../components/monitor/Monitor";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../firebase/config";
import {
  getAllCourse,
  getAllCourseATTD,
  getUserCourse,
  getUser,
} from "../../../firestore/collection";
import {
  collection,
  query,
  where,
  getDocFromCache,
  setDoc,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { useNavigate, useOutletContext } from "react-router-dom";
import useSWR, { useSWRConfig, mutate } from "swr";
import { Button, Text } from "@chakra-ui/react";

type userProperty = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  institution: string;
  major: string;
  yearClass: string;
  role: boolean;
  offerPromotion: boolean;
  type: string;
  credit: string;
};

type courseAirtimeProperty = {
  airtime_order: number;
  datetiem_start: Date;
  datetime_end: Date;
  airtime_code: string;
};

type courseTeacherProperty = {
  teacher_name: string;
  teacher_code: string;
};

type courseProperty = {
  course_airtime_list: Array<courseAirtimeProperty>;
  course_attend_limit: number;
  course_attend_url: string;
  course_attend_url_type: string;
  course_code: string;
  course_desc: string;
  course_name: string;
  course_post_evaluation_googleform_url: string;
  course_teacher_list: Array<courseTeacherProperty>;
};
const App = () => {
  const [user, loading, error] = useAuthState(auth);
  const [userData, setUserData] = useState<userProperty>();
  const [courseAll, setCourseAll] = useState<object>();
  const [userCredit, setUserCredit] = useState<string>();
  const [courseAllATTD, setCourseAllATTD] = useState<object>();
  const [userId, setUserId] = useState<string | null>(null);

  const navigate = useNavigate();
  const {
    data: dataAllCourse,
    error: errorSWRAll,
    isLoading: lodingAll,
    mutate: allCourse,
  } = useSWR("/courses/all", getAllCourse);
  const {
    data: dataAllATTD,
    error: errorSWRATTD,
    isLoading: lodingATTD,
    mutate: allATTD,
  } = useSWR("/courses/allATTD", getAllCourseATTD);
  const {
    data: dataUserCourse,
    error: errorSWRUserCourse,
    isLoading: loadingUserCourse,
    mutate: mutateUserCourse,
  } = useSWR(
    user ? { url: "/courses/my-course", user: user } : null,
    getUserCourse
  );
  const {
    data: userDatax,
    error: errorSWRUserDatax,
    isLoading: loadingUserDatax,
    mutate: mutateUserDatax,
  } = useSWR(user ? { url: "/api/users", user: user } : null, getUser);

  const { mutate } = useSWRConfig();
  // console.log(userData);
  useEffect(() => {
    if (!user) navigate("/");
    setCourseAll(dataAllCourse as any);
    setCourseAllATTD(dataAllATTD as any);
    setUserData(userDatax as any);
    if (user) {
      let uid = user.uid;
      const getDataTest = async (useruid: string) => {
        // const docRef = doc(db, "users", useruid);
        // const docSnap = await getDoc(docRef);

        // if (docSnap.exists()) {
        //     console.log("Document data:", docSnap.data());
        // } else {
        // // doc.data() will be undefined in this case
        //     console.log("No such document!");
        // }

        const q = query(collection(db, "users"), where("uid", "==", useruid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          setUserData(doc.data().student);
          setUserCredit(doc.data().student.credit);
        });
      };

      if (!userData) {
        // getDataTest(uid);
        setUserId(uid);
      } else {
        // console.log(userData, "userData");
      }
    }
    // console.log(userData);
  }, [user, dataUserCourse, userDatax]);

  // console.log(userData, "in course")

  function mutateAll() {
    mutateUserDatax();
    allCourse();
    allATTD();
    mutateUserCourse();
  }

  // console.log(courseAll);
  return (
    <>
      {/* <Button onClick={reRenderAll} colorScheme={'red'}>Test Mutate</Button> */}
      <Monitor
        {...courseAll}
        course_list_attd={dataAllATTD && dataAllATTD.course_list}
        userData={userData}
        userId={userId}
        userCourse={dataUserCourse}
        mutateAll={mutateAll}
      />
    </>
  );
};

export default App;
