import React from 'react';
import {
    AspectRatio,
    Icon,
    Box,
    Stack,
    Heading,
    Text,
    useBreakpointValue,
    useDisclosure,
    Divider
} from '@chakra-ui/react';
import { BsYoutube } from 'react-icons/bs';
import { RiGroupFill } from 'react-icons/ri';
import useSWR from 'swr';
import { getCoursePost, getAllCourse } from '../../../firestore/collection'
import {
	userType,
	userStudentType,
	courseType
} from '../../../firestore'

import ModalBegin from '../components/Modal';
const courseSkillType = [
    { courseType: "L", courseTypeTextEng:'Listening', courseTypeText: "ฟัง", active:true },
    { courseType: "S", courseTypeTextEng:'Speaking', courseTypeText: "พูด", active:true  },
    { courseType: "R", courseTypeTextEng:'Reading', courseTypeText: "อ่าน", active:true  },
    { courseType: "W", courseTypeTextEng:'Writing', courseTypeText: "เขียน", active:true  },
]

type coursePostType = {
    course_code:string,
    post_video_url:string,
    course:courseType,
}

const ContentBodyModal = (props:any) => {
    const { url } = props;
    const urlSrc = `https://www.youtube.com/embed/${url}`;
    return <AspectRatio maxW='560px'>
        <iframe
            width="560"
            height="315"
            src={urlSrc}
            title="YouTube video player" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen
        />
    </AspectRatio>
}

const CourseHistory = (props:any) => {
    const [url, setUrl] = React.useState<string>("");
    const [activeCourse, setActiveCourse] = React.useState<string>('');
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const { data:dataCoursePost, error:errorCoursePost, isLoading:loadingCoursePost} = useSWR('course/post', getCoursePost);
    const { data:dataAllCourse, error:errorCourse, isLoading:loadingCourse, mutate:mutateCourse } = useSWR("course/all", getAllCourse);
    const mdShowVideo = useDisclosure();
    // console.log(dataCoursePost);

    const openModal = (courseCode:string, courseUrl:string) => {
        mdShowVideo.onOpen()
        
        
        const urlSplit = courseUrl.split('.be/')[1];
        // console.log(urlSplit);
        setUrl(urlSplit)
        setActiveCourse(courseCode);
    }
    
    
    return (
        <Box p='1.75rem'>
            <Stack spacing='5'>
                <Heading fontWeight={'bold'}>คอร์สย้อนหลัง</Heading>
                { 
                courseSkillType && 
                courseSkillType.map((val:{
                    courseType:string,
                    courseTypeText:string,
                    courseTypeTextEng:string,
                    active:boolean}, idx:number) => {
                        const courseByType = dataCoursePost && dataCoursePost.filter((v:coursePostType, i:number) => v.course.course_skill_type === val.courseType);
                        
                        return dataCoursePost && <Box key={idx} mt='3'>
                            <Text fontSize='1.5rem'>{val.courseTypeText}</Text>
                            <Stack mt='3'>
                                {
                                    courseByType && courseByType.map((v:coursePostType, i:number) => {
                                        return <Box key={i} bgColor='white' p='3' borderRadius='7' boxShadow='md' onClick={() => openModal(v.course_code, v.post_video_url)} cursor="pointer" _hover={{backgroundColor:'lightblue'}} transition='.2s ease-in-out'>
                                            {/* BsYoutube */}
                                            <Stack direction={{base:'column', lg:'row'}} justify={{lg:'space-between'}} align={{lg:'center'}}>
                                                <Stack spacing='3' direction={{base:'column', lg:'row'}}>
                                                    <Stack direction={{base:'row'}} justify={{base:'space-between'}}>
                                                        <Heading fontSize='1.5rem'>{v.course.course_name}</Heading>
                                                        { isDesktop && <Divider orientation='vertical' /> }
                                                        <Text>รหัสวิชา : {v.course_code}</Text>
                                                    </Stack>
                                                    <Stack direction={{base:'column', lg:'row'}}>
                                                        { isDesktop && <Divider orientation='vertical' /> }
                                                        <Text>ครูผู้สอน</Text>
                                                        <Stack direction='row'>
                                                            <Icon as={RiGroupFill}/>
                                                            <Text>{v.course.course_teacher_list[0].teacher_name}</Text>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack direction={{base:'column', lg:'row'}} spacing={{base:'0', lg:'2'}}>
                                                        { isDesktop && <Divider orientation='vertical' /> }
                                                        <Text>รายละเอียด</Text>
                                                        <Text>{v.course.course_desc}</Text>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={{base:'row', lg:'row'}} align='center'>
                                                    <Heading fontSize='1rem'>คลิกดูวีดีโอ</Heading>
                                                    <Icon as={BsYoutube} color='red' />
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    })
                                }
                                
                            </Stack>
                        </Box>;
                }) 
                }

            </Stack>
            <ModalBegin 
                isOpen={mdShowVideo.isOpen}
                onOpen={mdShowVideo.onOpen}
                onClose={mdShowVideo.onClose}
                Title={activeCourse}
                contentBody={<ContentBodyModal url={url} />}
            />
        </Box>
        )
}

export default CourseHistory;