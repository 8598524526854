import React, {useState, useEffect } from 'react';
import {
    Button,
    Stack,
    Flex,
    Text
} from '@chakra-ui/react';
import { useNavigate, Link, Outlet } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../firebase/config';
import { getAllCourse, getAllCourseATTD, getUser } from '../../firestore/collection';
import { collection, query, where, getDocFromCache, setDoc, doc, getDoc, getDocs} from "firebase/firestore";
import Monitor from './components/monitor/Monitor';
import Header from './components/Header';
import Footer from './components/Footer';
import useSWR, { useSWRConfig } from 'swr';
import PreTest from './components/PreTest';

type userProperty = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    institution: string;
    major : string;
    yearClass : string;
    role: boolean;
    offerPromotion: boolean;
    type: string;
}

type courseAirtimeProperty = {
    airtime_order: number;
    datetiem_start: Date;
    datetime_end: Date;
    airtime_code: string;
}

type courseTeacherProperty = {
    teacher_name: string;
    teacher_code: string;
}

type courseProperty = {
    course_airtime_list: Array<courseAirtimeProperty>;
    course_attend_limit: number;
    course_attend_url: string;
    course_attend_url_type: string;
    course_code : string;
    course_desc: string;
    course_name: string;
    course_post_evaluation_googleform_url:string;
    course_teacher_list: Array<courseTeacherProperty>;
}

const App = (props: any) => {
    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState<userProperty>();
    const [currentUser, setCurrentUser] = useState();
    const [uid, setUid] = useState<string>();
    const [courseAll, setCourseAll] = useState<object>();
    const [courseAllATTD, setCourseAllATTD] = useState<object>();
    const navigate = useNavigate();
    // const { data:dataAllCourse, error:errorSWRAll, isLoading:lodingAll } = useSWR('/courses/all', getAllCourse);
    // const { data:dataAllATTD, error:errorSWRATTD, isLoading:lodingATTD } = useSWR('/courses/allATTD', getAllCourseATTD);
    // // const { data:dataAllATTD, error:errorSWRATTD, isLoading:lodingATTD } = useSWR('/courses/allATTD', getAllCourseATTD);
    const {
        data: userDatax,
        error: errorSWRUserDatax,
        isLoading: loadingUserDatax,
        mutate: mutateUserDatax,
      } = useSWR(user ? { url: "/api/users", user: user } : null, getUser);
    // const { mutate } = useSWRConfig();
    
      useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user as any);
        })
      }, [])

    // console.log(dataAllCourse, 'in layoutUser')
    useEffect(() => {
        // console.log(userDatax, 'in layout');
        // console.log(user);
        if (!user) navigate('/');
        // setCourseAll(dataAllCourse as any);
        // setCourseAllATTD(dataAllATTD as any);
        if(user) {
            let uid = user.uid;
            setUid(uid);
            const getDataTest = async(useruid : string) => {
                // const docRef = doc(db, "users", useruid);
                // const docSnap = await getDoc(docRef);
            
                // if (docSnap.exists()) {
                //     console.log("Document data:", docSnap.data());
                // } else {
                // // doc.data() will be undefined in this case
                //     console.log("No such document!");
                // }
            
                const q = query(collection(db, "users"), where("uid", "==", useruid));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    setUserData(doc.data().student);
                });
            
            }

            if(!userData) {
                getDataTest(uid);
            } else {
                // console.log(userData, "userData");
            }

        }
    }, [user, userDatax]);
    


    const testMutate = () => {
        // mutate('/courses/all');
        // console.log(data);
    }
    return (
        process.env.REACT_APP_MODE === "PROD" ?
        // XXX
        <Stack w='100%' spacing='0'>
            <Header user={user} userData={userDatax} uid={uid} callMutate={mutateUserDatax} />
            {/* <Button colorScheme="red" onClick={testMutate}>mutate</Button> */}
                <Flex 
                    direction="column" flex="1"
                    minH={{base: '100vh', md: '100vh'}}
                    backgroundImage={'./beams.jpg'} 
                    backgroundPosition="center"
                    backgroundSize="cover"
                    w='100%'
                    >
                        <Outlet context={{userData, uid, user}}/>
                </Flex>
                    {/* {props.children && props.children} */}
            {/* <Monitor {...courseAll}  course_list_attd={dataAllATTD && dataAllATTD.course_list} />  */}
            <Footer />
        </Stack> : 
        <Stack w='100%'>
            <PreTest userData={userData}/>
        </Stack>
    )

}

export default App;