import {
  Image,
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  Text,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { 
  HamburgerIcon, 
  AddIcon,
  ExternalLinkIcon,
  RepeatIcon,
  EditIcon, 
} from '@chakra-ui/icons';
import { FiMenu } from 'react-icons/fi'
import { Logo } from './Logo'
import { landingPageMenuList } from '../../../home/variables';

export const Header = (props: any) => {

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Box 
      as="section" 
      pb={{ base: '6', md: '6' }} 
      w='100%'
      // position="sticky" 
      // zIndex="sticky" 
      top={0}>
    {/*<Box as="section" w='100%'>*/}
      <Box as="nav" bg="bg-surface" boxShadow="sm" w='100%'
          // position="fixed"  
          // backgroundColor="rgba(255, 255, 255, 0.8)" 
          // backdropFilter="saturate(180%) blur(5px)" 
          // w="100%"
          // zIndex={999}

        >
        <Container 
          py={{ base: '4', lg: '5' }}  
          w='100%'
          >
          <HStack spacing="10" justify="space-between">
            {/* <Logo /> */}
            <Box>
              <Image src='./images/uniclass_logo_crop.png' w='32' />
            </Box>
            {
            
            isDesktop ? (
              <Flex justify="space-between" flex="1">
                <ButtonGroup variant="link" spacing="8">
                  {landingPageMenuList.map((item:{title:string, link: string}, idx:number) => {
                    return (
                      process.env.REACT_APP_MODE === 'PROD' ?
                      <Button key={idx}>
                        <ChakraLink href={item.link}>
                          {item.title}
                        </ChakraLink>
                      </Button> :
                      item.link !== "#booking-course" && 
                      <Button key={idx}>
                        <ChakraLink href={item.link}>
                          {item.title}
                        </ChakraLink>
                      </Button>


                    )
                  })}
                </ButtonGroup>
                {
                  process.env.REACT_APP_MODE === 'PROD' &&
                  <HStack spacing="3">
                    <Button id={'app_aaa'} onClick={props.mdOpen ? props.mdOpen : ''} colorScheme={"twitter"}>ลงทะเบียน / เข้าสู่ระบบ</Button>
                  </HStack>
                }
              </Flex>
            ) : (
              // <IconButton
              //   variant="ghost"
              //   icon={<FiMenu fontSize="1.25rem" />}
              //   aria-label="Open Menu"
              // />
              process.env.REACT_APP_MODE === 'PROD' &&
              <HStack spacing="3">
                {/* <Button variant="ghost">Sign in</Button> */}
                <Button id={'app_aaa2'} onClick={props.mdOpen ? props.mdOpen : ''} colorScheme={"twitter"}>ลงทะเบียน / เข้าสู่ระบบ</Button>
              </HStack>
            /*
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='outline'
                />
                <MenuList>
                  <MenuItem>
                      <ChakraLink href="#booking">
                        จองคอร์สเรียน
                      </ChakraLink>
                  </MenuItem>
                  <MenuItem icon={<AddIcon />} command='⌘T'>
                    New Tab
                  </MenuItem>
                  <MenuItem icon={<ExternalLinkIcon />} command='⌘N'>
                    New Window
                  </MenuItem>
                  <MenuItem icon={<RepeatIcon />} command='⌘⇧N'>
                    Open Closed Tab
                  </MenuItem>
                  <MenuItem icon={<EditIcon />} command='⌘O'>
                    Open File...
                  </MenuItem>
                </MenuList>
              </Menu>
              */
            )
            
            }
          </HStack>
        </Container>
      </Box>
    </Box>
  )
}
